// ** Reactstrap Imports
import {Fragment, useState} from 'react'
import {useDropzone} from 'react-dropzone'
import {DownloadCloud, FileText, X} from 'react-feather'
import {
    Button,
    Col,
    ListGroup,
    Label,
    ListGroupItem,
    Modal,
    ModalBody,
    ModalHeader,
    Row
} from 'reactstrap'
// ** Store & Actions
import '@styles/react/libs/file-uploader/file-uploader.scss'
import '@styles/react/libs/flatpickr/flatpickr.scss'
import toast from 'react-hot-toast'
import {useDispatch} from 'react-redux'
import {addJobCodeFile, setFileType} from '../store'
import JobCodeExcell from '../../../../../assets/sgkDeclaration/ornek_toplu_meslek_kodu_guncelle.xlsx'
import {Controller, useForm} from 'react-hook-form'
import Cleave from "cleave.js/react"


const defaultValues = {
    securityKey: ""
}
const JobCodeFilePopup = ({open, toggleJobCodeFilePopup}) => {
    const [active, setActive] = useState('1')

    const [companyId, setCompanyId] = useState(null)
    const [securityKey, setSecurityKey] = useState("")

    const toggle = tab => {
        if (active !== tab) {
            setActive(tab)
        }
    }

    const {
        control,
        formState: {errors}
    } = useForm({defaultValues})

    const handleChange = event => {
        setSecurityKey(event.target.value)
    }
    const dispatch = useDispatch()
    const [files, setFiles] = useState([])
    const {getRootProps, getInputProps} = useDropzone({
        multiple: false,
        onDrop: result => {
            if (result.length && result[0].name.endsWith('xlsx')) {
                setFiles([...result.map(file => Object.assign(file))])
            } else {
                toast.error(
                    () => (
                        <p className='mb-0'>
                            Sadece <span className='fw-bolder'>.xlsx</span>, <span
                            className='fw-bolder'>.xls</span> &{' '} Yükleyebilirsin!.
                        </p>
                    ),
                    {
                        style: {
                            minWidth: '380px'
                        }
                    }
                )
            }
        }
    })

    const renderFilePreview = file => {
        if (file.type.startsWith('image')) {
            return <img className='rounded' alt={file.name} src={URL.createObjectURL(file)} height='28' width='28'/>
        } else {
            return <FileText size='28'/>
        }
    }

    const handleRemoveFile = file => {
        const uploadedFiles = files
        const filtered = uploadedFiles.filter(i => i.name !== file.name)
        setFiles([...filtered])
    }

    const renderFileSize = size => {
        if (Math.round(size / 100) / 10 > 1000) {
            return `${(Math.round(size / 100) / 10000).toFixed(1)} mb`
        } else {
            return `${(Math.round(size / 100) / 10).toFixed(1)} kb`
        }
    }

    const _downloadExcel = () => {
        const link = document.createElement('a')
        const filename = 'ornek_toplu_meslek_kodu_guncelle.xlsx'
        link.setAttribute('href', JobCodeExcell)
        link.setAttribute('download', filename)
        link.click()
    }

    const fileList = files.map((file, index) => (
        <ListGroupItem key={`${file.name}-${index}`} className='d-flex align-items-center justify-content-between'>
            <div className='file-details d-flex align-items-center'>
                <div className='file-preview me-1'>{renderFilePreview(file)}</div>
                <div>
                    <p className='file-name mb-0'>{file.name}</p>
                    <p className='file-size mb-0'>{renderFileSize(file.size)}</p>
                </div>
            </div>
            <Button color='danger' outline size='sm' className='btn-icon' onClick={() => handleRemoveFile(file)}>
                <X size={14}/>
            </Button>
        </ListGroupItem>
    ))

    const closePopup = () => {
        setFiles([])
        toggle("1")
        dispatch(setFileType("1"))
        setCompanyId(null)
        setSecurityKey("")
        toggleJobCodeFilePopup()
    }

    const onSubmit = async () => {
        const data = new FormData()
        if (files.length !== 0) {
            const file = files[0]
            data.append('File', file, file.name)
        } else {
            data.append('File', null)
        }
        closePopup()
        dispatch(addJobCodeFile(data))
    }

    const optionsSecurityKey = {blocks: [6], numericOnly: true}

    return (
        <>
            <Modal isOpen={open} toggle={closePopup} className='modal-dialog-centered modal-lg'>
                <ModalHeader className='bg-transparent' toggle={closePopup}></ModalHeader>
                <ModalBody className='px-sm-5 mx-50 pb-5'>
                    <div className='text-center mb-2'>
                        <h1 className='mb-1'>Toplu meslek kodu güncelle</h1>
                    </div>
                    <Fragment>
                        <Row>
                            <Col lg={12} md={12}>
                                <Button.Ripple block outline color='warning' onClick={() => _downloadExcel()}>
                                    Örnek Meslek Kodu Güncelleme Excel Dosyasını İndir
                                </Button.Ripple>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm='12'>
                                <div {...getRootProps({className: 'dropzone'})}>
                                    <input {...getInputProps()} />
                                    <div className='d-flex align-items-center justify-content-center flex-column'>
                                        <DownloadCloud size={64}/>
                                        <h5>Dosyaları buraya bırakın veya yüklemek için tıklayın</h5>
                                        <p className='text-secondary'>
                                            Dosyaları buraya bırakın veya tıklayın{' '}
                                            <a href='/' onClick={e => e.preventDefault()}>
                                                Araştır
                                            </a>{' '}
                                        </p>
                                    </div>
                                </div>
                                {files.length ? (
                                    <Fragment>
                                        <ListGroup className='my-2'>{fileList}</ListGroup>
                                    </Fragment>
                                ) : null}
                                {companyId !== null && companyId.isPoliceHotelWorkerDeclarationEmploye ? (<Row>
                                    <Col sm="12">
                                        <div className="mb-1">
                                            <Label className="form-label" for="title">
                                                Güvenlik Kodu <span className="text-danger">*</span>
                                            </Label>
                                            <Controller
                                                name="securityKey"
                                                control={control}
                                                render={({field}) => (
                                                    <Cleave className="form-control" id="securityKey" placeholder=""
                                                            invalid={errors.name && true} {...field}
                                                            options={optionsSecurityKey}
                                                            onChange={handleChange} value={securityKey}/>
                                                )}
                                            />
                                        </div>
                                    </Col>
                                </Row>) : null}
                            </Col>
                        </Row>
                        <Row>
                            <Col sm='12'>
                                <Button type='button' className='me-1' color='primary' onClick={onSubmit}>
                                    Bildir
                                </Button>
                                <Button type='reset' color='secondary' outline onClick={closePopup}>
                                    İptal
                                </Button>
                            </Col>

                        </Row>
                    </Fragment>
                </ModalBody>
            </Modal>
        </>
    )
}

export default JobCodeFilePopup