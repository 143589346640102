// ** Dropdowns Imports
import {getUserData} from '@utils'
import Select from 'react-select'
import UserDropdown from "./UserDropdown"

// ** Third Party Components
import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'

// ** Actions
import {selectedCompany} from '@store/authentication'

const NavbarUser = () => {
    const dispatch = useDispatch()
    const store = useSelector((state) => state.auth)
    const user = getUserData()

    useEffect(() => {
        if (user !== null && user?.companyGroupList && store.selectedCompanyGroup === 0) {
            dispatch(selectedCompany(user?.companyGroupList[0]))
        }
    }, [])

    const customStyles = {
        container: (provided) => ({
            ...provided,
            minWidth: '220px'
        }),
        control: (provided) => ({
            ...provided,
            minWidth: '220px'
        })
    }

    // Büyük-küçük harf duyarsız filtreleme fonksiyonu
    const customFilterOption = (option, inputValue) => {
        return option.label.toLocaleLowerCase('tr').includes(inputValue.toLocaleLowerCase('tr'))
    }

    return (
        <ul className="nav navbar-nav align-items-center ms-auto">
            <Select
                isClearable={false}
                options={user?.companyGroupList}
                onChange={(item) => dispatch(selectedCompany(item))}
                value={user?.companyGroupList?.filter(x => x.value === store.selectedCompanyGroup)}
                className='react-select'
                classNamePrefix='select'
                styles={customStyles}
                filterOption={customFilterOption}
            />
            <UserDropdown/>
        </ul>
    )
}
export default NavbarUser
