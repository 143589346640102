// ** Redux Imports
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import {createTab} from "../../../../redux/tabManagement"
// ** Axios Imports
import axios from "axios"
import toast from "react-hot-toast"

export const getData = createAsyncThunk("visit/getData", async (params) => {
    const url = `/api/visits/0/`
    const response = await axios.get(url, {params})
    return {
        params,
        data: response.data.visits,
        totalPages: response.data.pageCount
    }

})

export const getArchiveData = createAsyncThunk("visit/getArchiveData", async (params) => {
    const url = `/api/visits/0/`
    const response = await axios.get(url, {params})
    return {
        params,
        data: response.data.visits,
        totalPages: response.data.pageCount
    }

})

export const ToggleVisitHtmlImagePopup = createAsyncThunk("visit/ToggleVisitHtmlImagePopup", (data) => {
    return data
})

export const toggleManualVisitDeclarationPopupState = createAsyncThunk("visit/popUpState", (status) => {
    return status
})

export const formResetState = createAsyncThunk("visit/formResetState", (status) => {
    return status
})


export const manualVisitDeclaration = createAsyncThunk("api/outSgkWorkerDeclaration", async (data, {
    dispatch,
    getState
}) => {
    const response = await axios.post("/api/visits/manualDeclaration", data)
    if (response.status === 200) {
        await dispatch(formResetState(!getState().visit.formReset))
        toast.success("İşleminiz Başlatıldı")
    }
    await dispatch(getData(getState().visit.params))

    return response
})

export const DeleteLossVisit = createAsyncThunk("Visit/DeleteLossVisit", async (data, {dispatch, getState}) => {
    const response = await axios.post("/api/visits/DeleteLossVisit", {id: data})
    if (response.status === 200) {
        toast.success("İşleminiz Başarılı")
        await dispatch(getData(getState().visit.params))
    }

    return response
})
export const exportExcel = createAsyncThunk("UnapprovedVisit/exportExcel", async (data, {getState}) => {
    console.log(data)
    const response = await axios.get(`/api/visits/UnapprovedExport`, {
        responseType: "arraybuffer",
        "Content-Type": "blob",
        params: {CompanyGroupId: getState().auth.selectedCompanyGroup, Q: getState().workAccident.params.q}
    })
    if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", "onay_bekleyen_vizite_listesi.xlsx")
        document.body.appendChild(link)
        link.click()
    }

})
export const approveVisit = createAsyncThunk("visit/approveVisit", (data, {getState}) => {
    const approveNewList = [...getState().visit.approvedList]
    const value = data.qualityState.split("-")[0]
    if (value !== "0") {
        const qualityState = data.qualityState.split("-")[1]
        if (qualityState === "1" || qualityState === "0" || qualityState === "2") {
            const newData = approveNewList.filter(x => x.visitId !== data.visitId)
            newData.push(data)
            return newData
        }
    } else {
        const identificationNumber = data.qualityState.split("-")[1]
        const newData = approveNewList.filter(x => x.identificationNumber !== identificationNumber)
        return newData
    }
})

export const setVisitList = createAsyncThunk("visit/setVisitList", (request, {getState}) => {
    const approveNewList = [...getState().visit.approvedList]
    const newDataList = []
    request.forEach(data => {
        const value = data.qualityState.split("-")[0]
        if (value !== "0") {
            const qualityState = data.qualityState.split("-")[1]
            if (qualityState === "1" || qualityState === "0" || qualityState === "2") {
                // const newData = approveNewList.filter(x => x.visitId !== data.visitId)
                newDataList.push(data)
            }
        } else {
            const identificationNumber = data.qualityState.split("-")[1]
            const newData = approveNewList.filter(x => x.identificationNumber !== identificationNumber)
            newDataList.push(newData)
        }
    })
    debugger
    return newDataList
})

export const approveVisitListClear = createAsyncThunk("visit/approveVisitListClear", () => {
    return []
})

export const approveCountPlus = createAsyncThunk("visit/approveCountPlus", (data) => {
    return data
})
export const setApproveSelectedList = createAsyncThunk("visit/setApproveSelectedList", (data) => {
    return data
})

export const getCompanyList = createAsyncThunk("visit/getCompanyList", async (data, {getState}) => {
    const response = await axios.get(`/api/companygroup/${getState().auth.selectedCompanyGroup}/companies`)
    return response.data
})

export const sendApprovedVisits = createAsyncThunk("visit/sendApprovedVisit", async (data, {dispatch, getState}) => {
    toast.loading('İşlem Tamamlanana Kadar Bekleyiniz...')
    const response = await axios.post("/api/visits/approveVisit", {visits: getState().visit.approvedList})
    toast.remove()
    if (response.status === 200) {
        await dispatch(getData(getState().visit.params))
        toast.success("İşleminiz başlamıştır!")
    }
    return response
})

export const getDetail = createAsyncThunk("visit/getDetail", async (data) => {
    const response = await axios.get(`/api/visits/getVisitDetail?tckn=${data.tckn}`)
    return {popupStatus: true, responseData: response}
})

export const detailPopupState = createAsyncThunk("visit/detailPopupState", (data, {dispatch}) => {
    dispatch(getDetail(data))
})

export const detailPopupStateClose = createAsyncThunk("visit/detailPopupStateClose", (data) => {
    return data
})

export const getApprovedVisitList = createAsyncThunk("visit/getApprovedVisitList", (data, {dispatch}) => {
    dispatch(createTab(data))
})
export const getCompanyDetails = createAsyncThunk("user/getCompanyDetails", (data, {dispatch}) => {
    console.log(data)
    dispatch(createTab(data))
})

export const getHtmlFile = createAsyncThunk("visit/getHtmlFile", async (data, {getState}) => {
    const response = await axios.get(`/api/visits/HtmlFile`, {
        responseType: "arraybuffer",
        "Content-Type": "blob",
        params: {ReportTrackingNo: getState().visit.toggleVisitHtmlImagePopup.visitReportNumber, VisitType: 1}
    })
    if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", "HtmlFile.html")
        document.body.appendChild(link)
        link.click()
    }

})

export const appVisitSlice = createSlice({
    name: "visit",
    initialState: {
        data: [],
        dataArchive: [],
        total: 1,
        totalArchive: 1,
        params: {},
        paramsArchive: {},
        allData: [],
        allDataArchive: [],
        manualVisitDeclarationStatus: false,
        approvedList: [],
        detailPopupStatus: false,
        formReset: false,
        detailTckn: 0,
        approveCount: 0,
        detailVisitData: [],
        companyList: [],
        selectedData: [],
        toggleVisitHtmlImagePopup: null,
        approveSelectedList: []
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data
                state.params = action.payload.params
                state.total = action.payload.totalPages
            })
            .addCase(getArchiveData.fulfilled, (state, action) => {
                state.dataArchive = action.payload.data
                state.paramsArchive = action.payload.params
                state.totalArchive = action.payload.totalPages
            })
            .addCase(toggleManualVisitDeclarationPopupState.fulfilled, (state, action) => {
                state.manualVisitDeclarationStatus = action.payload
            })
            .addCase(approveVisit.fulfilled, (state, action) => {
                const newApproveSelectedList = []
                action.payload.forEach(x => newApproveSelectedList.push(x.qualityState))
                state.approveSelectedList = newApproveSelectedList
                state.approvedList = action.payload
            })
            .addCase(approveVisitListClear.fulfilled, (state, action) => {
                state.approveSelectedList = []
                state.approvedList = action.payload
            })
            .addCase(setVisitList.fulfilled, (state, action) => {
                state.approvedList = action.payload
            })
            .addCase(setApproveSelectedList.fulfilled, (state, action) => {
                state.approveSelectedList = action.payload
            })
            .addCase(sendApprovedVisits.fulfilled, (state, action) => {
                state.approvedList = []
                state.approveCount = state.approveCount + 1
                console.log(action.payload)
            })
            .addCase(approveCountPlus.fulfilled, (state, action) => {
                state.approveCount = action.payload
            })
            .addCase(detailPopupStateClose.fulfilled, (state, action) => {
                state.detailPopupStatus = action.payload
            })
            .addCase(getDetail.fulfilled, (state, action) => {
                state.detailPopupStatus = action.payload.popupStatus
                state.detailVisitData = action.payload.responseData
            })
            .addCase(getCompanyList.fulfilled, (state, action) => {
                state.companyList = action.payload
            })
            .addCase(formResetState.fulfilled, (state, action) => {
                state.formReset = action.payload
            })
            .addCase(ToggleVisitHtmlImagePopup.fulfilled, (state, action) => {
                state.toggleVisitHtmlImagePopup = action.payload
            })
    }
})

export default appVisitSlice.reducer
