// ** React Imports
import React, { useState } from 'react'

// ** Reactstrap Imports
import { Button, Col, Modal, ModalBody, ModalHeader, Row, Form } from 'reactstrap'

// ** Flatpickr Import
import Flatpickr from 'react-flatpickr'
import '@styles/react/libs/flatpickr/flatpickr.scss'
import 'flatpickr/dist/plugins/monthSelect/style.css'

// ** Plugin Import
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect'

// ** Store & Actions
import { useDispatch, useSelector } from 'react-redux'
import { getXmlData, setXmlExportPopup } from '../store'

// ** Flatpickr Localization
import { Turkish } from 'flatpickr/dist/l10n/tr.js'

const XmlExportPopup = () => {
    // ** Store Vars
    const dispatch = useDispatch()
    const store = useSelector(state => state.iskur)

    // ** Local State for Form Inputs
    const [selectedDate, setSelectedDate] = useState(new Date())

    // ** Close Modal Handler
    const popupClose = () => {
        dispatch(setXmlExportPopup({ show: false }))
    }

    // ** Form Submit Handler
    const handleSubmit = (e) => {
        e.preventDefault()
        const year = selectedDate.getFullYear()
        const month = selectedDate.getMonth() + 1
        console.log('Selected Year:', year)
        console.log('Selected Month:', month)
        dispatch(setXmlExportPopup({ show: false }))
        dispatch(getXmlData({ companyId: store.selectedCompany.id, year, month, companyName: store.selectedCompany.name }))
    }

    return (
        <Modal isOpen={store.xmlExportPopupShow} toggle={popupClose} className='modal-dialog-centered modal-lg'>
            <ModalHeader className='bg-transparent' toggle={popupClose}></ModalHeader>
            <ModalBody className='px-sm-5 mx-50 pb-5'>
                <div className='text-center mb-2'>
                    <h2 className='mb-1'>{store.selectedCompany?.groupName || 'Group Name'}</h2>
                    <h1 className='mb-1'>{store.selectedCompany?.name || 'Company Name'}</h1>
                    <h3 className='mb-1'>{store.selectedCompany?.registirationNumber || 'Registration Number'}</h3>
                    <h4 className='mb-1'>{store.selectedCompany?.taxNumber || 'Tax Number'}</h4>
                </div>
                <Row>
                    <Col md="12" className="d-flex justify-content-center"> {/* Center the input */}
                        <Form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="yearMonthPicker" className="form-label">Periyot seçiniz</label>
                                <Flatpickr
                                    id="yearMonthPicker"
                                    className="form-control"
                                    options={{
                                        plugins: [
                                            monthSelectPlugin({
                                                shorthand: true, // Use short month names
                                                dateFormat: 'Y-m', // Display Year-Month format
                                                altFormat: 'F Y'  // Display Month Name and Year
                                            })
                                        ],
                                        defaultDate: selectedDate, // Default date is the current selected date
                                        locale: Turkish,
                                        onChange: ([selected]) => {
                                            setSelectedDate(selected)
                                        }
                                    }}
                                    style={{
                                        width: '200px', // Set a smaller width for the input
                                        textAlign: 'center' // Ensure text is centered inside the input
                                    }}
                                />
                            </div>
                            <Button color="primary" type="submit" block>
                                İndir
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    )
}

export default XmlExportPopup
