// ** React Imports
import {useState, Fragment, useEffect} from 'react'
import axios from 'axios'

// ** Reactstrap Imports
import {Row, Col, Card, Form, CardBody, Button, Badge, Modal, Input, Label, ModalBody, ModalHeader} from 'reactstrap'

// ** Third Party Components
import {useDispatch} from 'react-redux'
import Swal from 'sweetalert2'
import {Check, Briefcase, X} from 'react-feather'
import {useForm, Controller} from 'react-hook-form'
import withReactContent from 'sweetalert2-react-content'

// ** Custom Components
import Avatar from '@components/avatar'

// ** Utils
import { avatarNameCleanerRegex } from '../../../../utility/Utils'

// ** Styles
import '@styles/react/libs/react-select/_react-select.scss'

import {updateCompany} from '../store'

const roleColors = {
    editor: 'light-info',
    admin: 'light-danger',
    author: 'light-warning',
    maintainer: 'light-success',
    subscriber: 'light-primary'
}

const statusColors = {
    active: 'light-success',
    pending: 'light-warning',
    inactive: 'light-secondary'
}

const MySwal = withReactContent(Swal)

const UserInfoCard = ({selectedCompany}) => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)

    const [sCompanyData, setCompanyData] = useState(null)
    useEffect(() => {
        axios.get(`/api/companies/${selectedCompany}`).then(item => {
            setCompanyData(item.data)
        })
    }, [])

    // ** Hook
    const {
        reset,
        control,
        // setError,
        handleSubmit,
        formState: {errors}
    } = useForm({
        defaultValues: {
            name: sCompanyData?.name,
            registirationNumber: sCompanyData?.registirationNumber,
            taxNumber: sCompanyData?.taxNumber
        }
    })

    // ** render user img
    const renderUserImg = () => {
        if (sCompanyData !== null && sCompanyData?.avatar) {
            return (
                <img
                    height='110'
                    width='110'
                    alt='user-avatar'
                    src={sCompanyData?.avatar}
                    className='img-fluid rounded mt-3 mb-2'
                />
            )
        } else {
            return (
                <Avatar
                    initials
                    color={sCompanyData?.avatarColor || 'light-primary'}
                    className='rounded mt-3 mb-2'
                    content={avatarNameCleanerRegex(sCompanyData?.name)}
                    contentStyles={{
                        borderRadius: 0,
                        fontSize: 'calc(48px)',
                        width: '100%',
                        height: '100%'
                    }}
                    style={{
                        height: '110px',
                        width: '110px'
                    }}
                />
            )
        }
    }

    const onSubmit = data => {
        dispatch(
            updateCompany({
                id: selectedCompany,
                name: data.name,
                registirationNumber: data.registrationNumber
            })
        )
    }

    const handleReset = () => {
        reset({
            name: sCompanyData?.name,
            registirationNumber: sCompanyData?.registirationNumber,
            taxNumber: sCompanyData?.taxNumber
        })
    }

    const handleSuspendedClick = () => {
        return MySwal.fire({
            title: 'Emin misiniz?',
            text: "Bu işlemi geri alamazsınız!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Evet, firmayı kapat!',
            cancelButtonText: 'İptal',
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ms-1'
            },
            buttonsStyling: false
        }).then(function (result) {
            if (result.value) {
                MySwal.fire({
                    icon: 'success',
                    title: 'Kapatıldı!',
                    text: 'Firma başarıyla kapatıldı.',
                    customClass: {
                        confirmButton: 'btn btn-success'
                    }
                })
            } else if (result.dismiss === MySwal.DismissReason.cancel) {
                MySwal.fire({
                    title: 'İptal',
                    text: 'İşlem iptal edildi.',
                    confirmButtonText: 'Tamam',
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-success'
                    }
                })
            }
        })
    }

    return (
        <Fragment>
            <Card>
                <CardBody>
                    <div className='user-avatar-section'>
                        <div className='d-flex align-items-center flex-column'>
                            {renderUserImg()}
                            <div className='d-flex flex-column align-items-center text-center'>
                                <div className='user-info'>
                                    <h4>{sCompanyData !== null ? sCompanyData?.fullName : 'Eleanor Aguilar'}</h4>
                                    {sCompanyData !== null ? (
                                        <Badge color={roleColors[sCompanyData?.role]} className='text-capitalize'>
                                            {sCompanyData?.role}
                                        </Badge>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-around my-2 pt-75'>
                        <div className='d-flex align-items-start'>
                            <Badge color='light-primary' className='rounded p-75'>
                                <Briefcase className='font-medium-2'/>
                            </Badge>
                            <div className='ms-75'>
                                <h4 className='mb-0'>{sCompanyData?.workerCount}</h4>
                                <small>Çalışan Sayısı</small>
                            </div>
                        </div>
                    </div>
                    <h4 className='fw-bolder border-bottom pb-50 mb-1'>Firma Detayları</h4>
                    <div className='info-container'>
                        {sCompanyData !== null ? (
                            <ul className='list-unstyled'>
                                <li className='mb-75'>
                                    <span className='fw-bolder me-25'>Grup Adı:</span>
                                    <span>{sCompanyData?.companyGroupName}</span>
                                </li>
                                <li className='mb-75'>
                                    <span className='fw-bolder me-25'>Kod Ad / Şube:</span>
                                    <span>{sCompanyData?.name}</span>
                                </li>
                                <li className='mb-75'>
                                    <span className='fw-bolder me-25'>Sicil Numarası:</span>
                                    <Badge className='text-capitalize'
                                           color={statusColors[sCompanyData?.registirationNumber]}>
                                        {sCompanyData?.registirationNumber}
                                    </Badge>
                                </li>
                                <li className='mb-75'>
                                    <span className='fw-bolder me-25'>Vergi Numarası:</span>
                                    <span
                                        className='text-capitalize'>{(sCompanyData.taxNumber ? (`00${sCompanyData.taxNumber}`).slice(-10) : '')}</span>
                                </li>
                            </ul>
                        ) : null}
                    </div>
                    <div className='d-flex justify-content-center pt-2'>
                        <Button color='primary' onClick={() => setShow(true)}>
                            Düzenle
                        </Button>
                        <Button className='ms-1' color='danger' outline onClick={handleSuspendedClick}>
                            Firmayı Kapat
                        </Button>
                    </div>
                </CardBody>
            </Card>
            <Modal isOpen={show} toggle={() => setShow(!show)} className='modal-dialog-centered modal-lg'>
                <ModalHeader className='bg-transparent' toggle={() => setShow(!show)}></ModalHeader>
                <ModalBody className='px-sm-5 pt-50 pb-5'>
                    <div className='text-center mb-2'>
                        <h1 className='mb-1'>Firma Bilgilerini Düzenle</h1>
                    </div>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row className='gy-1 pt-75'>
                            <Col xs={12}>
                                <Label className='form-label' for='name'>
                                    Kod Ad / Şube
                                </Label>
                                <Controller
                                    defaultValue={sCompanyData?.name}
                                    control={control}
                                    id='name'
                                    name='name'
                                    render={({field}) => (
                                        <Input {...field} id='name' invalid={errors.name && true}/>
                                    )}
                                />
                            </Col>
                            <Col xs={12}>
                                <Label className='form-label' for='name'>
                                    Sicil No
                                </Label>
                                <Controller
                                    defaultValue={sCompanyData?.registrationNumber}
                                    control={control}
                                    id='registrationNumber'
                                    name='registrationNumber'
                                    render={({field}) => (
                                        <Input {...field} id='registrationNumber'
                                               invalid={errors.registrationNumber && true}/>
                                    )}
                                />
                            </Col>
                            <Col xs={12} className='text-center mt-2 pt-50'>
                                <Button type='submit' className='me-1' color='primary'>
                                    Güncelle
                                </Button>
                                <Button
                                    type='reset'
                                    color='secondary'
                                    outline
                                    onClick={() => {
                                        handleReset()
                                        setShow(false)
                                    }}
                                >
                                    İptal
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default UserInfoCard
