// ** React Imports
import {Fragment, useEffect, useState} from "react"
// ** Table Columns
import {columns} from "./columns"
// ** Store & Actions
import {useDispatch, useSelector} from "react-redux"
import {
    getArchiveData,
    sendApprovedVisits,
    toggleManualVisitDeclarationPopupState,
    exportExcel,
    ToggleVisitHtmlImagePopup,
    setVisitList,
    approveVisitListClear,
    approveCountPlus,
    setApproveSelectedList
} from "../store"
// ** Third Party Components
import DataTable from "react-data-table-component"
import {ChevronDown, Copy, File, FileText, Grid, Printer, Share} from "react-feather"
import Flatpickr from "react-flatpickr"
import ReactPaginate from "react-paginate"
import ManualVisitDeclarationPopup from "../view/ManualVisitDeclarationPopup"
import VisitDetailPopup from "../view/VisitDetailPopup"
import VisitHtmlImagePopup from "../view/VisitHtmlImagePopup"
import ApprovedVisitList from "../../visit-approved/list/Table"
// ** Reactstrap Imports
import {
    Button, Card, CardBody, CardHeader, CardTitle, Col, DropdownItem, DropdownMenu, DropdownToggle, Input,
    TabContent,
    TabPane,
    Label,
    Nav, NavItem, NavLink, Row, UncontrolledDropdown
} from "reactstrap"

import Select from "react-select"

// ** Styles
import "@styles/react/libs/react-select/_react-select.scss"
import "@styles/react/libs/tables/react-dataTable-component.scss"

// ** Table Header
const CustomHeader = ({
                          store,
                          handlePerPage,
                          rowsPerPage,
                          handleFilter,
                          searchTerm,
                          selectedRows,
                          dispatch,
                          currentPage,
                          setApproveVisitChange
                      }) => {
    // ** Converts table to CSV
    function convertArrayOfObjectsToCSV(array) {
        let result

        const columnDelimiter = ","
        const lineDelimiter = "\n"
        const keys = Object.keys(store.data[0])

        result = ""
        result += keys.join(columnDelimiter)
        result += lineDelimiter

        array.forEach(item => {
            let ctr = 0
            keys.forEach(key => {
                if (ctr > 0) result += columnDelimiter

                result += item[key]

                ctr++
            })
            result += lineDelimiter
        })

        return result
    }

    // ** Downloads CSV
    function downloadCSV(array) {
        const link = document.createElement("a")
        let csv = convertArrayOfObjectsToCSV(array)
        if (csv === null) return

        const filename = "export.csv"

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`
        }

        link.setAttribute("href", encodeURI(csv))
        link.setAttribute("download", filename)
        link.click()
    }

    const dispatch1 = useDispatch()

    return (
        <div className="invoice-list-table-header w-100 me-1 ms-50 mt-2 mb-75">
            <Row>
                <Col xl="6" className="d-flex align-items-center p-0">
                    <div className="d-flex align-items-center w-100">
                        <label htmlFor="rows-per-page">Toplam</label>
                        <Input
                            className="mx-50"
                            type="select"
                            id="rows-per-page"
                            value={rowsPerPage}
                            onChange={handlePerPage}
                            style={{width: "5rem"}}
                        >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                        </Input>

                    </div>
                </Col>
                <Col
                    xl="6"
                    className="d-flex align-items-sm-center justify-content-xl-end justify-content-start flex-xl-nowrap flex-wrap flex-sm-row flex-column pe-xl-1 p-0 mt-xl-0 mt-1"
                >
                    <div className="col-md-4 d-flex align-items-center mb-sm-0 mb-1 me-1">
                        <label className="mb-0" htmlFor="search-invoice">
                            Ara:
                        </label>
                        <Input
                            id="search-invoice"
                            className="ms-50 w-100"
                            type="text"
                            value={searchTerm}
                            onChange={e => handleFilter(e.target.value)}
                        />
                    </div>

                    <div className="d-flex align-items-center table-header-actions">
                        <UncontrolledDropdown className="me-1">
                            <DropdownToggle color="secondary" caret outline>
                                <Share className="font-small-4 me-50"/>
                                <span className="align-middle">Dışa Aktar</span>
                            </DropdownToggle>
                            <DropdownMenu container="body">
                                <DropdownItem className="w-100">
                                    <Printer className="font-small-4 me-50"/>
                                    <span className="align-middle">Print</span>
                                </DropdownItem>
                                <DropdownItem className="w-100" onClick={() => downloadCSV(store.data)}>
                                    <FileText className="font-small-4 me-50"/>
                                    <span className="align-middle">CSV</span>
                                </DropdownItem>
                                <DropdownItem className="w-100" onClick={() => dispatch(exportExcel(store.data))}>
                                    <Grid className="font-small-4 me-50"/>
                                    <span className="align-middle">Excel</span>
                                </DropdownItem>
                                <DropdownItem className="w-100">
                                    <File className="font-small-4 me-50"/>
                                    <span className="align-middle">PDF</span>
                                </DropdownItem>
                                <DropdownItem className="w-100">
                                    <Copy className="font-small-4 me-50"/>
                                    <span className="align-middle">Copy</span>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        <Button style={{width: "150px", height: "50px"}} className="add-new-user me-1" color="primary"
                                onClick={() => dispatch1(toggleManualVisitDeclarationPopupState(true))
                                }>
                            Manuel Bildirim
                        </Button>
                        <Button style={{width: "130px", height: "50px"}} className="add-new-user me-1" color="success"
                                onClick={() => dispatch1(sendApprovedVisits(true))
                                }>
                            Onayla
                        </Button>
                    </div>
                </Col>
            </Row>
            <Row>
                {selectedRows.length > 0 &&
                    <SelectVisits dataSource={selectedRows} currentPage={currentPage} searchTerm={searchTerm}
                                  setApproveVisitChange={setApproveVisitChange}/>}
            </Row>
        </div>
    )
}

const UsersList = () => {
    // ** Store Vars
    const dispatch = useDispatch()
    const store = useSelector(state => state.visit)
    const authStore = useSelector(state => state.auth)


    // ** States
    const [sort, setSort] = useState("desc")
    const [searchTerm, setSearchTerm] = useState("")
    const [currentPage, setCurrentPage] = useState(1)
    const [setApproveVisitChange, updateSetApproveVisitChange] = useState(1)
    const [sortColumn, setSortColumn] = useState("id")
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [selectedRows, setSelectedRows] = useState([])
    const [handleClearSelectedRows, setHandleClearSelectedRows] = useState(false)
    const toggleVisitHtmlImagePopup = () => dispatch(ToggleVisitHtmlImagePopup({show: false, image: null}))

    console.log(store.approvedList)
    const getDataParameter = () => {
        return {
            sort,
            sortColumn,
            q: searchTerm,
            page: currentPage,
            pageSize: rowsPerPage,
            visitType: 5,
            CompanyGroupId: authStore.selectedCompanyGroup
        }
    }

    // ** Get data on mount
    useEffect(() => {
        dispatch(getArchiveData(getDataParameter()))
        dispatch(approveVisitListClear())
    }, [dispatch, store.dataArchive.length, authStore.selectedCompanyGroup, sort, sortColumn, currentPage])

    useEffect(() => {
        if (currentPage > 1) setCurrentPage(1)
        else dispatch(getArchiveData(getDataParameter()))
        dispatch(approveVisitListClear())
    }, [searchTerm])

    // ** Function in get data on page change
    const handlePagination = page => {
        setCurrentPage(page.selected + 1)
        setHandleClearSelectedRows(!handleClearSelectedRows)
    }

    // ** Function in get data on rows per page
    const handlePerPage = e => {
        const value = parseInt(e.currentTarget.value)
        // dispatch(
        //     getArchiveData(getDataParameter())
        // )
        setRowsPerPage(value)
    }

    // ** Function in get data on search query change
    const handleFilter = val => {
        setSearchTerm(val)
        // dispatch(
        //     getArchiveData(getDataParameter())
        // )
    }

    // ** Custom Pagination
    const CustomPagination = () => {
        //const count = Number(Math.ceil(store.total / rowsPerPage))

        return (
            <ReactPaginate
                previousLabel={""}
                nextLabel={""}
                pageCount={store.totalArchive || 1}
                activeClassName="active"
                forcePage={currentPage !== 0 ? currentPage - 1 : 0}
                onPageChange={page => handlePagination(page)}
                pageClassName={"page-item"}
                nextLinkClassName={"page-link"}
                nextClassName={"page-item next"}
                previousClassName={"page-item prev"}
                previousLinkClassName={"page-link"}
                pageLinkClassName={"page-link"}
                containerClassName={"pagination react-paginate justify-content-end my-2 pe-1"}
            />
        )
    }

    // ** Table data to render
    const dataToRender = () => {
        const filters = {
            q: searchTerm
        }

        const isFiltered = Object.keys(filters).some(function (k) {
            return filters[k].length > 0
        })

        if (store.dataArchive.length > 0) {
            return store.dataArchive
        } else if (store.dataArchive.length === 0 && isFiltered) {
            return []
        } else {
            return store.allDataArchive.slice(0, rowsPerPage)
        }
    }
    console.log(store.approvedList)

    const handleSort = (column, sortDirection) => {
        setSort(sortDirection)
        setSortColumn(column.sortField)
        // dispatch(
        //     getArchiveData(getDataParameter())
        // )
    }

    function onSelectedRowsChanged(item) {
        setSelectedRows(item.selectedRows)

        const newApproveSelectedList = [...store.approveSelectedList]
        newApproveSelectedList.forEach((x, index) => {
            const selectedItem = item.selectedRows.find(z => {
                return z.approveSelectList.find(y => y.value === x)
            })
            if (!selectedItem) {
                newApproveSelectedList.splice(index, 1)
            }
        })
        dispatch(setApproveSelectedList(newApproveSelectedList))

        if (item.selectedRows.length === 0) {
            dispatch(approveCountPlus(store.approveCount + 1))
        }

        updateSetApproveVisitChange(setApproveVisitChange + 1)
    }

    return (
        <Fragment>
            <Card className="overflow-hidden">
                <div className="react-dataTable">
                    <DataTable
                        noHeader
                        subHeader
                        sortServer
                        pagination
                        responsive
                        paginationServer
                        selectableRows={true}
                        selectableRowDisabled={(row) => row.isStaffState}
                        onSelectedRowsChange={onSelectedRowsChanged}
                        clearSelectedRows={handleClearSelectedRows}
                        columns={columns(store.approveSelectedList)}
                        onSort={handleSort}
                        sortIcon={<ChevronDown/>}
                        className="react-dataTable"
                        paginationComponent={CustomPagination}
                        data={dataToRender()}
                        subHeaderComponent={
                            <CustomHeader
                                store={store}
                                searchTerm={searchTerm}
                                rowsPerPage={rowsPerPage}
                                handleFilter={handleFilter}
                                handlePerPage={handlePerPage}
                                selectedRows={selectedRows}
                                dispatch={dispatch}
                                currentPage={currentPage}
                                setApproveVisitChange={setApproveVisitChange}
                            />
                        }
                    />
                </div>
            </Card>
            <ManualVisitDeclarationPopup open={store.manualVisitDeclarationStatus}/>
            <VisitDetailPopup open={store.detailPopupStatus}/>
            <VisitHtmlImagePopup open={store.toggleVisitHtmlImagePopup?.show}
                                 toggleSidebar={toggleVisitHtmlImagePopup}/>
        </Fragment>
    )
}


function SelectVisits({dataSource, currentPage, searchTerm, setApproveVisitChange}) {
    const dispatch = useDispatch()
    const store = useSelector(state => state.visit)
    const [selectedValue, setSelectedValue] = useState({name: "default", label: "Islem tipi seciniz"})
    const options = [
        {
            value: -1,
            label: "Seçiniz"
        },
        {
            value: 1,
            label: "Çalıştı olarak isaretle"
        },
        {
            value: 0,
            label: "Çalışmadı olarak isaretle"
        }
    ]

    function setApproveList(item) {
        const updatedItemList = [
            ...store.data.map(x => ({
                ...x,
                approveSelectList: x.approveSelectList.map(z => ({...z, selected: false}))
            }))
        ]
        const approveSelectedList = []
        const approveVisitList = []
        updatedItemList.forEach(x => {
            const selectedIndex = dataSource.findIndex(z => z.reportNo === x.reportNo)
            if (selectedIndex === -1) return x

            const approvelist = x.approveSelectList.filter(z => !z.isDisabled)
            const selectedDataList = approvelist.filter(z => parseInt(z.value.substring(11)) === item.value)
            if (selectedDataList.length > 0) {
                const selectedData = selectedDataList[selectedDataList.length - 1]
                x.approveSelectList.some(z => {
                    if (z.value === selectedData.value) {
                        approveSelectedList.push(z.value)
                        approveVisitList.push({
                            qualityState: z.value,
                            identificationNumber: x.identificationNumber,
                            case: x.case,
                            medulaReportId: x.medularReportId,
                            date: x.reportEndDate,
                            visitId: x.visitId
                        })
                        return true
                    }
                    return false
                })
            }
            return x
        })
        dispatch(setVisitList(approveVisitList))
        dispatch(setApproveSelectedList(approveSelectedList))
    }

    function onChange(item) {
        setSelectedValue(item)
        setApproveList(item)
    }

    useEffect(() => {
        onChange({value: -1, label: "Seçiniz"})
        dispatch(approveVisitListClear())
    }, [currentPage, store.approveCount, searchTerm])


    useEffect(() => {
        setApproveList(selectedValue)
    }, [setApproveVisitChange])


    return (
        <div style={{display: "flex"}}>
            <div className="col-1 mt-2">Secilenleri</div>
            <div className="col-4 mt-1">
                <Select
                    isClearable={false}
                    options={options}
                    onChange={onChange}
                    className="react-select"
                    classNamePrefix="select"
                    value={selectedValue}
                />
            </div>
        </div>
    )
}

export default UsersList
