import '@styles/react/libs/flatpickr/flatpickr.scss'
import Cleave from 'cleave.js/react'
import Flatpickr from 'react-flatpickr'
import {Controller, useForm} from 'react-hook-form'
import {useDispatch, useSelector} from 'react-redux'
import Select from 'react-select'
import {Button, Form, Input, Label, Modal, ModalBody, ModalHeader} from 'reactstrap'
import {jobCodeUpdateApi, toggleJobCodePopup} from '../store'
// ** Configs
import {useEffect, useState} from 'react'
import axios from 'axios'

// import toast from "react-hot-toast"


const JobCodeUpdatePopup = ({open}) => {
    const store = useSelector(state => state.sgkWorkerDeclaration)
    const dispatch = useDispatch()
    const [options, setOptions] = useState([])
    const [inputValue, setInputValue] = useState('')

    const {
        control,
        handleSubmit,
        reset,
        formState: {errors}
    } = useForm({
        defaultValues: {
            identificationNumber: "",
            jobName: "",
            companyId: ""
        }
    })
    const popupClose = () => {
        dispatch(toggleJobCodePopup(false))
        reset()
    }

    const onSubmit = async (data) => {
        dispatch(
            jobCodeUpdateApi({
                identificationNumber: data.identificationNumber ? data.identificationNumber : 0,
                jobName: data.jobName ? data.jobName.value.toString() : "",
                companyId: data.company ? data.company.value : 0
            })
        )
    }

    useEffect(() => {
        reset()
    }, [store.jobCodePopupStatus])

    const fetchOptions = async (input) => {
        if (!input) {
            setOptions([])
            return
        }

        try {
            const response = await axios.get(`/api/CommonValue/Job`, {
                params: {Query: input}
            })

            const fetchedOptions = response.data.map((item) => ({
                value: item.value,
                label: `${item.value} - ${item.name}`
            }))

            setOptions(fetchedOptions)
        } catch (error) {
            console.error('Error fetching options:', error)
        }
    }

    const handleInputChange = (newValue) => {
        setInputValue(newValue)
        fetchOptions(newValue)
    }
    const optionsTcKimlikNo = {blocks: [11], numericOnly: true}
    return (
        <>
            <Modal isOpen={open} toggle={popupClose} className='modal-dialog-centered modal-lg'>
                <ModalHeader className='bg-transparent' toggle={popupClose}></ModalHeader>
                <ModalBody className='px-sm-5 mx-50 pb-5'>
                    <div className='text-center mb-2'>
                        <h1 className='mb-1'>SGK</h1>
                        <p>Meslek Kodu Güncelle</p>
                    </div>

                    <Form style={{}} onSubmit={handleSubmit(onSubmit)}>
                        <div className='mb-1'>
                            <Label className='form-label' for='company'>
                                Sicil <span className='text-danger'>*</span>
                            </Label>
                            <Controller
                                name='company'
                                control={control}
                                render={({field}) => (
                                    <Select
                                        isClearable={false}
                                        options={store.companyList}
                                        className='react-select'
                                        classNamePrefix='select'
                                        {...field}
                                        onChange={e => { 
                                            field.onChange(e)
                                        }}
                                    />

                                )}
                            />
                        </div>
                        <div className='mb-1'>
                            <Label className='form-label' for='identificationNumber'>
                                T.C. Kimlik No <span className='text-danger'>*</span>
                            </Label>
                            <Controller
                                name='identificationNumber'
                                control={control}
                                render={({field}) => (
                                    <Cleave className='form-control' id='identificationNumber' placeholder=''
                                            invalid={errors.name && true} {...field} options={optionsTcKimlikNo}/>
                                )}
                            />
                        </div>
                        <div className='mb-1'>
                            <Label className='form-label' for='jobName'>
                                Sigortalı Meslek Kodu <span className='text-danger'>*</span>
                            </Label>
                            <Controller
                                name='jobName'
                                control={control}
                                render={({field}) => (
                                    <Select
                                        className='react-select'
                                        classNamePrefix='select'
                                        options={options}
                                        onInputChange={handleInputChange}
                                        inputValue={inputValue}
                                        placeholder="Aramak için yazın..."
                                        isClearable
                                        {...field}
                                    />
                                )}
                            />
                        </div>
                        <Button type='submit' className='me-1' color='primary'>
                            Kaydet
                        </Button>
                        <Button type='reset' color='secondary' className="me-1" outline onClick={popupClose}>
                            İptal
                        </Button>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )
}
export default JobCodeUpdatePopup