import React from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button
} from 'reactstrap'

const AutoApproveModal = ({ isOpen, toggle, onConfirm }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} className='modal-dialog-centered modal-md'>
      <ModalHeader toggle={toggle} className='bg-transparent'>Otomatik Onaylama</ModalHeader>
      <ModalBody >
        Bu özelliği aktif ettiğinizde, viziteler çalışmadı şeklinde otomatik olarak onaylanacaktır. Devam etmek istiyor musunuz?
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onConfirm}>
          Onayla
        </Button>
        <Button color="secondary" onClick={toggle}>
          Vazgeç
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default AutoApproveModal