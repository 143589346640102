import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { Line } from 'react-chartjs-2'
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement } from 'chart.js'
import { useDispatch, useSelector } from 'react-redux'
import { getQueueMetricsData } from '../store'
import flatpickrConfig from "@configs/flatpickrConfig"
import Flatpickr from "react-flatpickr"
import { formatDateForSwagger } from '../../../../utility/Utils'
import { CircularProgress } from '@mui/material'

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement)

const QueuePerformance = () => {
  const dispatch = useDispatch()

  const queueMetricsData = useSelector(state => state.queue?.queueMetricsData || [])
  const loading = useSelector(state => state.queue.queueMetricsDataLoading)

  const [selectedGroups, setSelectedGroups] = useState([])
  const [startDate, setStartDate] = useState(new Date().toISOString().split('T')[0])
  const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0])

  useEffect(() => {
    dispatch(getQueueMetricsData({ startDate, queueName: '', includeErrorQueues: false, endDate }))
  }, [dispatch, startDate, endDate])

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
        <CircularProgress />
      </div>
    )
  }

  const queueMetricsDataLabels = Array.from(new Set(queueMetricsData.map(item => new Date(item.createdAt).toLocaleString())))

  const groupedData = queueMetricsData.reduce((acc, item) => {
    const group = item.name.split('-')[0]
    if (!acc[group]) acc[group] = []
    acc[group].push(item)
    return acc
  }, {})

  const queueMetricsDataDatasets = Object.keys(groupedData).map(group => {
    const groupData = groupedData[group]

    const data = queueMetricsDataLabels.map(label => {
      const dataPoint = groupData.find(item => new Date(item.createdAt).toLocaleString() === label)
      return dataPoint ? dataPoint.messages : 0
    })

    return {
      label: group,
      data,
      borderColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
      fill: false,
      tension: 0.1,
      hidden: !selectedGroups.includes(group)
    }
  })

  const data = {
    labels: queueMetricsDataLabels,
    datasets: queueMetricsDataDatasets
  }

  const queueLineChartOptions = {
    responsive: true,
    animation: false,
    elements: {
      point: {
        radius: 5
      }
    },
    scales: {
      y: {
        beginAtZero: false,
        ticks: {
          min: 0,
          stepSize: 10
        }
      }
    },
    maintainAspectRatio: false, // Added this line to make the chart responsive
    plugins: {
      legend: {
        display: false
      }
    }
  }

  const groupOptions = Object.keys(groupedData).map(group => ({
    value: group,
    label: group
  }))

  const handleGroupChange = selectedOptions => {
    setSelectedGroups(selectedOptions.map(option => option.value))
  }

  return (
    <div style={{ width: '100%', maxWidth: '1600px', margin: '0 auto' }}>
      {/* Date input */}
      <div style={{ display: 'flex', justifyContent: 'space-between', gap: '10px', marginBottom: '10px' }}>
        <div style={{ flex: 1 }}>
          <label htmlFor="startDate">Başlangıç Tarihi: </label>
          <Flatpickr
            className="form-control"
            id="startDate"
            value={startDate}
            options={flatpickrConfig}
            onChange={e => setStartDate(formatDateForSwagger(e))}
            style={{ width: '100%', padding: '10px', fontSize: '1rem' }}
          />
        </div>
        <div style={{ flex: 1 }}>
          <label htmlFor="endDate">Bitiş Tarihi: </label>
          <Flatpickr
            className="form-control"
            id="endDate"
            value={endDate}
            options={flatpickrConfig}
            onChange={e => setEndDate(formatDateForSwagger(e))}
            style={{ width: '100%', padding: '10px', fontSize: '1rem' }}
          />
        </div>
      </div>

      {/* Multi-Select Dropdown */}
      <div style={{ marginBottom: '10px' }}>
        <label htmlFor="groupSelect">Görüntülemek istediğiniz kuyrukları seçiniz: </label>
        <Select
          id="groupSelect"
          isMulti
          options={groupOptions}
          onChange={handleGroupChange}
          placeholder="Henüz bir kuyruk seçmediniz"
          styles={{ container: (base) => ({ ...base, width: '100%' }) }}
        />
      </div>

      {/* Line Chart */}
      <div style={{ width: '100%', height:'600px'}}>
        <Line data={data} options={queueLineChartOptions} />
      </div>
    </div>
  )
}

export default QueuePerformance