// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
export const getData = createAsyncThunk('edeclarations/getData', async (params, { getState }) => {

  let url = `/api/edeclarations?PageSize=${params.pageSize}&page=${params.page}&Q=${params.q}&CompanyId=&CompanyGroupId=${getState().auth.selectedCompanyGroup}`
  if (params.registrationNumber !== "") {
    url += `&registrationNumber=${params.registrationNumber}`
  }
  if (params.name !== "") {
    url += `&name=${params.name}`
  }
  if (params.groupName !== "") {
    url += `&groupName=${params.groupName}`
  }
  if (params.taxNumber !== null) {
    url += `&taxNumber=${params.taxNumber}`
  }
  const response = await axios.get(url)
  return {
    params,
    data: response.data.edeclarations,
    totalPages: response.data.pageCount
  }
})

export const downLoadFile = createAsyncThunk('edeclarations/downloadFile', async (path) => {
  const response = await axios.get(`/api/edeclarations/getfile?fileName=${path}`, { responseType: 'blob' })
  const url = window.URL.createObjectURL(response.data)
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', path.split('/')[path.split('/').length - 1])
  document.body.appendChild(link)
  link.click()
  console.log(response)
})

export const appEdeclarationSlice = createSlice({
  name: 'appedeclarations',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    companyGroupSelectData: [],
    companyPasswords: [],
    selectedEdeclaration: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
  }
})

export const exportExcel = createAsyncThunk("edeclarations/exportExcel", async (data, {getState}) => {
  console.log(data)
  const response = await axios.get(`/api/Edeclarations/Export`, {
      responseType: "arraybuffer",
      "Content-Type": "blob",
      params: {CompanyGroupId: getState().auth.selectedCompanyGroup}
  })
  if (response.status === 200) {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", "tahakkuk_ve_hizmet_listesi.xlsx")
      document.body.appendChild(link)
      link.click()
  }
})

export default appEdeclarationSlice.reducer
