import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Select,
  MenuItem,
  TextField,
  OutlinedInput,
  Checkbox,
  ListItemText,
  Chip,
  Tooltip
} from '@mui/material'
import { styled, alpha } from '@mui/material/styles'
import {
  FiFilter,
  FiCheckCircle,
  FiAlertCircle,
  FiClock
} from 'react-icons/fi'

const StyledCard = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius * 2,
  backgroundColor: '#ffffff',
  boxShadow: '0 4px 20px 0 rgba(0,0,0,0.05)'
}))

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: 'none',
  border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
  '& .MuiTable-root': {
    borderCollapse: 'separate',
    borderSpacing: '0 4px'
  },
  '& .MuiTableCell-root': {
    border: 'none',
    padding: '8px 16px'
  },
  '& .MuiTableRow-root': {
    transition: 'all 0.2s ease',
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.04)
    }
  },
  '& .MuiTableHead-root .MuiTableRow-root': {
    backgroundColor: 'transparent'
  }
}))

const StyledFilterBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  marginBottom: 0,
  flexWrap: 'wrap',
  alignItems: 'center',
  padding: theme.spacing(2),
  backgroundColor: alpha(theme.palette.background.default, 0.6),
  borderRadius: theme.shape.borderRadius * 2,
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#ffffff',
    borderRadius: theme.shape.borderRadius,
    transition: 'all 0.2s ease',
    '&:hover': {
      boxShadow: '0 2px 8px 0 rgba(0,0,0,0.1)'
    },
    '&.Mui-focused': {
      boxShadow: `0 0 0 2px ${alpha(theme.palette.primary.main, 0.2)}`
    }
  }
}))

const StatusChip = styled(Chip)(({ status, theme }) => {
  const getStatusColor = () => {
    switch (status) {
      case 'Başarılı':
        return {
          backgroundColor: alpha('#4caf50', 0.1),
          color: '#2e7d32'
        }
      case 'Hatalı':
        return {
          backgroundColor: alpha('#f44336', 0.1),
          color: '#d32f2f'
        }
      case 'Bekliyor':
        return {
          backgroundColor: alpha('#ff9800', 0.1),
          color: '#ed6c02'
        }
      default:
        return {
          backgroundColor: theme.palette.grey[100],
          color: theme.palette.grey[700]
        }
    }
  }
  return {
    ...getStatusColor(),
    fontWeight: 600,
    padding: '4px 12px'
  }
})

const HeaderTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  marginBottom: theme.spacing(3),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1)
}))

const QueueCompanyGroupCard = ({ data }) => {
  const queueCompanyStatusData = useSelector(state => state.queue.queueCompanyStatusData)
  const selectedCompanyGroup = queueCompanyStatusData.find(item => item.companyGroupId === data)

  const [statusFilter, setStatusFilter] = useState('')
  const [companyFilter, setCompanyFilter] = useState('')
  const [schedulerTypeFilter, setSchedulerTypeFilter] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  if (!selectedCompanyGroup) {
    return <Typography>Bu grup için veri bulunamadı.</Typography>
  }

  const uniqueSchedulerTypes = Array.from(new Set(selectedCompanyGroup.subSchedulers.map(subScheduler => subScheduler.schedulerType)))

  const filteredSubSchedulers = selectedCompanyGroup.subSchedulers.filter(subScheduler => (statusFilter ? subScheduler.subSchedulerStatus === statusFilter : true) &&
    (companyFilter ? subScheduler.companyName.toLowerCase().includes(companyFilter.toLowerCase()) : true) &&
    (schedulerTypeFilter.length > 0 ? schedulerTypeFilter.includes(subScheduler.schedulerType) : true)
  )

  const handleChangePage = (event, newPage) => setPage(newPage)
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleStatusFilterChange = (event) => {
    setStatusFilter(event.target.value)
    setPage(0)
  }
  const handleCompanyFilterChange = (event) => {
    setCompanyFilter(event.target.value)
    setPage(0)
  }
  const handleSchedulerTypeFilterChange = (event) => {
    const value = event.target.value
    setSchedulerTypeFilter(typeof value === 'string' ? value.split(',') : value)
    setPage(0)
  }

  const getStatusIcon = (status) => {
    switch (status) {
      case 'Başarılı':
        return <FiCheckCircle size={16} />
      case 'Hatalı':
        return <FiAlertCircle size={16} />
      case 'Bekliyor':
        return <FiClock size={16} />
      default:
        return null
    }
  }

  return (
    <StyledCard>
    <HeaderTypography variant="h5">
      <FiFilter  />
      {selectedCompanyGroup.companyGroupName} Grubuna Ait Şirketlerin Kuyrukları
    </HeaderTypography>

    <StyledFilterBox>
      <Select
        value={statusFilter}
        onChange={handleStatusFilterChange}
        displayEmpty
        size="small"
        sx={{
          minWidth: 130,
          '& .MuiSelect-select': {
            py: 0.7,
            typography: 'caption'
          }
        }}
        MenuProps={{
          PaperProps: {
            style: {
              padding: '4px'
            }
          },
          MenuListProps: {
            style: {
              padding: '0'
            }
          }
        }}
      >
        <MenuItem value="" sx={{ typography: 'caption' }}>Tüm Durumlar</MenuItem>
        <MenuItem value="Başarılı" sx={{ typography: 'caption' }}>Başarılı</MenuItem>
        <MenuItem value="Hatalı" sx={{ typography: 'caption' }}>Hatalı</MenuItem>
        <MenuItem value="Bekliyor" sx={{ typography: 'caption' }}>Bekliyor</MenuItem>
      </Select>

      <Select
        multiple
        displayEmpty
        value={schedulerTypeFilter}
        onChange={handleSchedulerTypeFilterChange}
        input={<OutlinedInput />}
        MenuProps={{
          PaperProps: {
            style: {
              padding: '4px'
            }
          },
          MenuListProps: {
            style: {
              padding: '0'
            }
          }
        }}
        renderValue={(selected) => (
          selected.length === 0 ? (
            <Typography color="text.secondary" variant="caption">Kuyruk Tipi</Typography>
          ) : (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip
                  key={value}
                  label={value}
                  size="small"
                  sx={{
                    height: '20px',
                    '& .MuiChip-label': {
                      px: 1,
                      fontSize: '0.75rem'
                    }
                  }}
                />
              ))}
            </Box>
          )
        )}
        size="small"
        sx={{
          minWidth: 150,
          '& .MuiSelect-select': { py: 0.7 }
        }}
      >
        {uniqueSchedulerTypes.map((type) => (
          <MenuItem
            key={type}
            value={type}
            sx={{
              padding: '4px 8px'
            }}
          >
            <Checkbox
              checked={schedulerTypeFilter.indexOf(type) > -1}
              size="small"
            />
            <ListItemText
              primary={type}
              primaryTypographyProps={{ variant: 'caption' }}
            />
          </MenuItem>
        ))}
      </Select>

      <TextField
        size="small"
        placeholder="Şirket Adı"
        value={companyFilter}
        onChange={handleCompanyFilterChange}
        variant="outlined"
        InputProps={{
          style: {
            fontSize: '0.75rem'
          }
        }}
        sx={{
          width: 150,
          '& .MuiOutlinedInput-root': {
            height: '36px',
            border: '1px solid #e0e0e0',
            '&:hover': {
              border: '1px solid #bdbdbd'
            },
            '&.Mui-focused': {
              border: '1px solid #1976d2'
            }
          },
          '& .MuiOutlinedInput-input': {
            typography: 'caption'
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
          },
          '& ::placeholder': {
            fontSize: '0.75rem',
            opacity: 0.7
          }
        }}
      />


    </StyledFilterBox>

      <StyledTableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Şirket Adı</TableCell>
              <TableCell>Kuyruk Tipi</TableCell>
              <TableCell>Durum</TableCell>
              <TableCell>Mesaj</TableCell>
              <TableCell>Başlangıç</TableCell>
              <TableCell>Bitiş</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredSubSchedulers
              // eslint-disable-next-line no-mixed-operators
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((subScheduler) => (
                <TableRow key={subScheduler.id}>
                  <TableCell>
                    <Typography variant="body2" fontWeight={500}>
                      {subScheduler.companyName}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={subScheduler.schedulerType}
                      size="small"
                      sx={{ backgroundColor: alpha('#1976d2', 0.1), color: '#1976d2' }}
                    />
                  </TableCell>
                  <TableCell>
                    <StatusChip
                      icon={getStatusIcon(subScheduler.subSchedulerStatus)}
                      label={subScheduler.subSchedulerStatus}
                      status={subScheduler.subSchedulerStatus}
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    {subScheduler.errorMessage ? (
                      <Tooltip title={subScheduler.errorMessage}>
                        <Typography
                          variant="body2"
                          sx={{
                            maxWidth: 200,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                          }}
                        >
                          {subScheduler.errorMessage}
                        </Typography>
                      </Tooltip>
                    ) : (
                      'N/A'
                    )}
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" color="text.secondary">
                      {new Date(subScheduler.createdAt).toLocaleString()}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" color="text.secondary">
                      {subScheduler.updatedAt ? new Date(subScheduler.updatedAt).toLocaleString() : 'N/A'}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </StyledTableContainer>

      <TablePagination
        component="div"
        count={filteredSubSchedulers.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          '.MuiTablePagination-select': {
            backgroundColor: '#ffffff'
          }
        }}
      />
    </StyledCard>
  )
}

export default QueueCompanyGroupCard