// ** Redux Imports
import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getData = createAsyncThunk('edeclarations/getData', async (params, { getState }) => {
  const { selectedCompanies, selectedPeriods, selectedLaws } = getState().edeclarations

  let url = `/api/edeclarations?PageSize=${params.pageSize}&page=${params.page}&Q=${params.q}&CompanyId=&CompanyGroupId=${getState().auth.selectedCompanyGroup}`

  if (params.registrationNumber !== "") {
    url += `&registrationNumber=${params.registrationNumber}`
  }
  if (params.name !== "") {
    url += `&name=${params.name}`
  }
  if (params.groupName !== "") {
    url += `&groupName=${params.groupName}`
  }
  if (params.taxNumber !== null) {
    url += `&taxNumber=${params.taxNumber}`
  }

  if (selectedCompanies.length > 0) {
    url += selectedCompanies.map(id => `&CompanyIds=${id}`).join('')
  }

  if (selectedPeriods.length > 0) {
    url += selectedPeriods.map(id => `&Periods=${id}`).join('')
  }
  if (selectedLaws.length > 0) {
    url += selectedLaws.map(id => `&Laws=${id}`).join('')
  }

  const response = await axios.get(url)

  return {
    params,
    data: response.data.edeclarations,
    totalPages: response.data.pageCount
  }
})


export const downLoadFile = createAsyncThunk('edeclarations/downloadFile', async (path) => {
  const response = await axios.get(`/api/edeclarations/getfile?fileName=${path}`, { responseType: 'blob' })
  const url = window.URL.createObjectURL(response.data)
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', path.split('/')[path.split('/').length - 1])
  document.body.appendChild(link)
  link.click()
  console.log(response)
})

export const getCompanyOptions = createAsyncThunk('edeclarations/getCompanyOptions', async (companyGroupId) => {
  const response = await axios.get(`/api/Edeclarations/CompanyOptions?companyGroupId=${companyGroupId}`)
  return response.data.companyOptions
})

export const getPeriodOptions = createAsyncThunk('edeclarations/getPeriodOptions', async (companyGroupId) => {
  const response = await axios.get(`/api/Edeclarations/PeriodOptions?companyGroupId=${companyGroupId}`)
  console.log("Period Options From Redux Call: ", response.data.periods)
  return response.data.periods
})

export const getLawOptions = createAsyncThunk('edeclarations/getLawOptions', async (companyGroupId) => {
  const response = await axios.get(`/api/Edeclarations/LawOptions?companyGroupId=${companyGroupId}`)
  return response.data.laws
})

export const clearFilters = createAction('edeclarations/clearFilters')

export const exportZip = createAsyncThunk("edeclarations/exportZip", async (data, { getState }) => {
  const { selectedCompanies, selectedPeriods, selectedLaws } = getState().edeclarations

  let url = `/api/Edeclarations/ExportZip?CompanyGroupId=${getState().auth.selectedCompanyGroup}`

  if (selectedCompanies.length > 0) {
    url += selectedCompanies.map(id => `&CompanyIds=${id}`).join('')
  }

  if (selectedPeriods.length > 0) {
    url += selectedPeriods.map(period => `&Periods=${period}`).join('')
  }

  if (selectedLaws.length > 0) {
    url += selectedLaws.map(law => `&Laws=${law}`).join('')
  }

  const response = await axios.get(url, {
    responseType: "arraybuffer",
    "Content-Type": "blob"
  })

  if (response.status === 200) {
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", "hero-tahakkuk.zip")
    document.body.appendChild(link)
    link.click()
  }
})

export const exportExcel = createAsyncThunk("edeclarations/exportExcel", async (data, { getState }) => {
  const { selectedCompanies, selectedPeriods, selectedLaws } = getState().edeclarations

  let url = `/api/Edeclarations/Export?CompanyGroupId=${getState().auth.selectedCompanyGroup}`

  if (selectedCompanies.length > 0) {
    url += selectedCompanies.map(id => `&CompanyIds=${id}`).join('')
  }

  if (selectedPeriods.length > 0) {
    url += selectedPeriods.map(period => `&Periods=${period}`).join('')
  }

  if (selectedLaws.length > 0) {
    url += selectedLaws.map(law => `&Laws=${law}`).join('')
  }

  const response = await axios.get(url, {
    responseType: "arraybuffer",
    "Content-Type": "blob"
  })

  if (response.status === 200) {
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", "tahakkuk_ve_hizmet_listesi.xlsx")
    document.body.appendChild(link)
    link.click()
  }
})

export const appEdeclarationSlice = createSlice({
  name: 'appedeclarations',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    companyGroupSelectData: [],
    companyPasswords: [],
    selectedEdeclaration: null,
    companyOptions: [],
    periodOptions: [],
    lawOptions: [],
    selectedCompanies: [],
    selectedPeriods: [],
    selectedLaws: [],
    isExportZipLoading: false,
    isExportExcelLoading: false
  },
  reducers: {
    setSelectedCompanies: (state, action) => {
      state.selectedCompanies = action.payload
    },
    setSelectedPeriods: (state, action) => {
      state.selectedPeriods = action.payload
    },
    setSelectedLaws: (state, action) => {
      state.selectedLaws = action.payload
    }

  },
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getCompanyOptions.fulfilled, (state, action) => {
        state.companyOptions = action.payload
      })
      .addCase(getPeriodOptions.fulfilled, (state, action) => {
        state.periodOptions = action.payload
      })
      .addCase(getLawOptions.fulfilled, (state, action) => {
        state.lawOptions = action.payload
      })
      .addCase(clearFilters, (state) => {
        state.selectedCompanies = []
        state.selectedPeriods = []
        state.selectedLaws = []
      })
      .addCase(exportZip.pending, (state) => {
        state.isExportZipLoading = true
      })
      .addCase(exportZip.fulfilled, (state) => {
        state.isExportZipLoading = false
      })
      .addCase(exportExcel.pending, (state) => {
        state.isExportExcelLoading = true
      })
      .addCase(exportExcel.fulfilled, (state) => {
        state.isExportExcelLoading = false
      })
  }
})

export const { setSelectedCompanies, setSelectedPeriods, setSelectedLaws } = appEdeclarationSlice.actions

export default appEdeclarationSlice.reducer
