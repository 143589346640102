import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    CardText,
    CardLink,
    Col,
    Row, Nav, NavItem, NavLink, TabContent, TabPane, Badge, Spinner
} from "reactstrap"
import {Bar, Doughnut, Pie} from 'react-chartjs-2'
import {Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement} from 'chart.js'
import {useEffect, useState} from "react"
import {BsCalendarDate} from "react-icons/bs"
import axios from "axios"
// import toast from "react-hot-toast"
import {useDispatch, useSelector} from 'react-redux'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement)

const Home = () => {
    const dispatch = useDispatch()
    const authStore = useSelector((state) => state.auth)
    const [dashData, setDashData] = useState(null)

    useEffect(() => {
        // Axios GET isteği
        axios.get('/api/dashboard', {params: {CompanyGruopId: authStore.selectedCompanyGroup}})
            .then(response => {
                // Başarılı olduğunda, yanıtı state'e atıyoruz
                setDashData(response.data)
            })
            .catch(error => {
                // Hata durumunda
                console.error('Veri alınırken bir hata oluştu:', error)
            })
    }, [dispatch, authStore.selectedCompanyGroup])

    const [active, setActive] = useState(0)
    const toggle = tab => {
        if (active !== tab) {
            setActive(tab)
        }
    }


    const data3 = {
        labels: dashData?.visit?.title,
        datasets: [
            {
                label: '# of Votes',
                data: dashData?.visit?.value,
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)'
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)'
                ],
                borderWidth: 1
            }
        ]
    }
    const options3 = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top'
            },
            tooltip: {
                enabled: true
            }
        }
    }

    const options2 = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top'
            }
        }
    }

    const data1 = {
        labels: ['İşe Giren Personel', 'İşten Ayrılan Personel'],
        datasets: [
            {
                label: 'Votes',
                data: [dashData?.employee?.entryCount, dashData?.employee?.outCount],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)'
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)'
                ],
                borderWidth: 1
            }
        ]
    }
    const options1 = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top'
            },
            tooltip: {
                enabled: true
            }
        }
    }

    const Loading = () => {
        return (
            <div className="text-center my-5">
                <Spinner style={{width: '3rem', height: '3rem'}} color="primary"/>
                <div>Yükleniyor...</div>
            </div>
        )
    }
    return (
        <div>
            {dashData !== null ? <>
                <Row>
                    <Col md='8'>
                        <Card>
                            <CardHeader>
                                <CardTitle>Çalışan İstatistikleri</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Nav fill pills>
                                    {dashData.employee.employeeTabsList.map((item, index) => (
                                        <NavItem key={index}>
                                            <NavLink
                                                active={active === index}
                                                onClick={() => toggle(index)}
                                            >
                                                {item.label}
                                            </NavLink>
                                        </NavItem>
                                    ))}
                                </Nav>

                                <TabContent className='py-50' activeTab={active}>

                                    {dashData.employee.employeeTabsList.map((item, index) => (
                                        <>
                                            <TabPane key={index} tabId={index}>
                                                <Bar data={{
                                                    labels: item.title,
                                                    datasets: [
                                                        {
                                                            label: item.label,
                                                            data: item.value,
                                                            backgroundColor: 'rgba(75, 192, 192, 0.2)',
                                                            borderColor: 'rgba(75, 192, 192, 1)',
                                                            borderWidth: 1
                                                        }
                                                    ]
                                                }} options={options2}/>
                                            </TabPane>
                                        </>
                                    ))}

                                </TabContent>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md='4'>
                        <Card>
                            <CardHeader>
                                <CardTitle>Vizite İstatistikler</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Pie data={data3} options={options3}/>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md='6'>
                        <Card style={{maxHeight: '400px', overflowY: 'auto'}}>
                            <CardHeader>
                                <CardTitle>Yaklaşan Doğum Günleri</CardTitle>
                            </CardHeader>
                            <CardBody>
                                {dashData.employee.birthDateList && dashData.employee.birthDateList.length > 0 ? (
                                    dashData.employee.birthDateList.map((birthDate, index) => (
                                        <CardText key={index}>
                                            <BsCalendarDate/> {birthDate}
                                        </CardText>
                                    ))
                                ) : (
                                    <p>Doğum tarihleri bulunamadı.</p>
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md='6'>
                        <Card>
                            <CardHeader>
                                <CardTitle>İşe Giren Çıkan Ortalaması</CardTitle>
                            </CardHeader>
                            <CardBody>

                                <Col md='6'><Doughnut data={data1} options={options1}/></Col>

                            </CardBody>
                        </Card>
                    </Col>

                </Row>
            </> : <><Loading/></>}

        </div>
    )
}

export default Home
