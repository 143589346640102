// ** React Imports
import { Fragment, useEffect, useState } from 'react'

// ** Table Columns
import { columns } from './columns'

// ** Store & Actions
import { useDispatch, useSelector } from 'react-redux'
import { clearFilters, exportExcel, exportZip, getCompanyOptions, getData, getLawOptions, getPeriodOptions } from '../store'

// ** Third Party Components
import DataTable from 'react-data-table-component'
import { ChevronDown, Grid } from 'react-feather'
import ReactPaginate from 'react-paginate'
import { LuFilterX } from "react-icons/lu"
import { FaRegFileZipper } from "react-icons/fa6"

// ** Reactstrap Imports
import {
  Button, Card, CardBody, CardHeader, CardTitle, Col, DropdownItem, DropdownMenu, DropdownToggle, Input,
  Label, Row, Spinner
} from 'reactstrap'

// ** Styles
import '@styles/react/libs/react-select/_react-select.scss'
import '@styles/react/libs/tables/react-dataTable-component.scss'

// ** Table Header
const CustomHeader = ({ handlePerPage, rowsPerPage, handleFilter, searchTerm }) => {
  const dispatch = useDispatch()
  const selectedCompanies = useSelector(state => state.edeclarations.selectedCompanies)
  const selectedPeriods = useSelector(state => state.edeclarations.selectedPeriods)
  const selectedLaws = useSelector(state => state.edeclarations.selectedLaws)
  const hasFilters = selectedCompanies.length > 0 || selectedPeriods.length > 0 || selectedLaws.length > 0
  const isExportZipLoading = useSelector(state => state.edeclarations.isExportZipLoading)
  const isExportExcelLoading = useSelector(state => state.edeclarations.isExportExcelLoading)

// ** Clear filters when clicked
const handleClearFilters = () => {
  dispatch(clearFilters())
  handleFilter("")  // Eğer bir arama filtresi de varsa onu da temizle
}

  // ** Converts table to CSV
  return (
    <div className='invoice-list-table-header w-100 me-1 ms-50 mt-2 mb-75'>
      <Row>
        <Col xl='6' className='d-flex align-items-center p-0'>
          <div className='d-flex align-items-center w-100'>
            <label htmlFor='rows-per-page'>Toplam</label>
            <Input
              className='mx-50'
              type='select'
              id='rows-per-page'
              value={rowsPerPage}
              onChange={handlePerPage}
              style={{ width: '5rem' }}
            >
              <option value='10'>10</option>
              <option value='25'>25</option>
              <option value='50'>50</option>
            </Input>
            <label htmlFor='rows-per-page'>Kayıt Göster</label>
          </div>
        </Col>
        <Col
          xl='6'
          className='d-flex align-items-sm-center justify-content-xl-end justify-content-start flex-xl-nowrap flex-wrap flex-sm-row flex-column pe-xl-1 p-0 mt-xl-0 mt-1'
        >


          <div className='d-flex align-items-center mb-sm-0 mb-1 '>
            <label className='mb-0' htmlFor='search-invoice'>
              Ara:
            </label>
            <Input
              id='search-invoice'
              className='ms-50 w-100'
              type='text'
              value={searchTerm}
              onChange={e => handleFilter(e.target.value)}
            />
          </div>

          {hasFilters && (
            <div>
              <Button.Ripple
                className="btn-icon btn-gray "
                style={{ marginLeft: 10, backgroundColor: 'transparent' }}
                outline
                onClick={handleClearFilters} // Clear filters on click
              >
                <LuFilterX size={16} className={"me-50"}/>
                <span>Filtreleri Kaldır</span>
              </Button.Ripple>
            </div>
          )}

          <div className='d-flex align-items-center table-header-actions'>
            <Button.Ripple
                onClick={() => dispatch(exportExcel(1))}
                className='btn-icon me-1 ms-1 btn-gray'
                style={{ backgroundColor: 'transparent'}}
                outline
                disabled={isExportExcelLoading}>
              {isExportExcelLoading ? (
                <Spinner size="sm" color="primary" className={"me-50"} />
              ) : (
                <Grid className='font-small-4 me-50' />
              )}
              {isExportExcelLoading ? 'Hazırlanıyor...' : 'Excele Aktar'}
            </Button.Ripple>
          </div>

          <div className='d-flex align-items-center table-header-actions'>
            <Button.Ripple
              onClick={() => dispatch(exportZip())}
              className='btn-icon btn-gray'
              style={{ backgroundColor: 'transparent' }}
              outline
              disabled={isExportZipLoading}
            >
              {isExportZipLoading ? (
                <Spinner size="sm" color="primary" className={"me-50"} />
              ) : (
                <FaRegFileZipper className='font-small-4 me-50' />
              )}
              {isExportZipLoading ? 'Hazırlanıyor...' : 'Zipe Aktar'}
            </Button.Ripple>
          </div>

        </Col>
      </Row>
    </div>
  )
}

const UsersList = () => {
  // ** Store Vars
  const dispatch = useDispatch()
  const store = useSelector(state => state.edeclarations)
  const authStore = useSelector(state => state.auth)

  // ** States
  const [sort, setSort] = useState('desc')
  const [searchTerm, setSearchTerm] = useState('')
  const [SearchRegistrationNumber, setSearchRegistrationNumber] = useState('')
  const [searchName, setSearchName] = useState('')
  const [SearchGroupName, setSearchGroupName] = useState('')
  const [searchTaxNumber, setSearchTaxNumber] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [sortColumn, setSortColumn] = useState('id')
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [isIsyeriDropdownOpen, setIsIsyeriDropdownOpen] = useState(false)
  const [isKanunDropdownOpen, setIsKanunDropdownOpen] = useState(false)
  const [isDonemDropdownOpen, setIsDonemDropdownOpen] = useState(false)
  const selectedCompanies = useSelector(state => state.edeclarations.selectedCompanies)
  const selectedPeriods = useSelector(state => state.edeclarations.selectedPeriods)
  const selectedLaws = useSelector(state => state.edeclarations.selectedLaws)

  // ** Function to toggle sidebar
  const toggleSidebar = () => setSidebarOpen(!sidebarOpen)
  const toggleIsyeriDropdown = () => setIsIsyeriDropdownOpen(!isIsyeriDropdownOpen)
  const toggleKanunDropdown = () => setIsKanunDropdownOpen(!isKanunDropdownOpen)
  const toggleDonemDropdown = () => setIsDonemDropdownOpen(!isDonemDropdownOpen)

  useEffect(() => {
    console.log("isIsyeriDropdownOpen", isIsyeriDropdownOpen)
  }, [isIsyeriDropdownOpen])


  const getDataParameter = () => {
    return {
      sort,
      sortColumn,
      q: searchTerm,
      page: currentPage,
      pageSize: rowsPerPage,
      registrationNumber: SearchRegistrationNumber,
      name: searchName,
      groupName: SearchGroupName,
      taxNumber: searchTaxNumber
    }
  }

  // ** Get data on mount
  useEffect(() => {
    dispatch(
      getData(getDataParameter())
    )
    dispatch(getCompanyOptions(authStore.selectedCompanyGroup))
    dispatch(getPeriodOptions(authStore.selectedCompanyGroup))
    dispatch(getLawOptions(authStore.selectedCompanyGroup))


  }, [dispatch, store.data.length, authStore.selectedCompanyGroup, sort, sortColumn, currentPage, searchTerm, selectedCompanies, selectedPeriods, selectedLaws])

  useEffect(() => {
    dispatch(
      getData(getDataParameter())
    )
  }, [SearchRegistrationNumber, SearchGroupName, searchName, searchTaxNumber])

  useEffect(() => {
    setCurrentPage(1)
  }, [selectedCompanies, selectedPeriods, selectedLaws])

  useEffect(() => {
      // unmount oldugunda filtreleri temizle
      return () => {
        dispatch(clearFilters())
      }
  }, [])

  // ** Function in get data on page change
  const handlePagination = page => {
    setCurrentPage(page.selected + 1)
  }

  // ** Function in get data on rows per page
  const handlePerPage = e => {
    const value = parseInt(e.currentTarget.value)
    setRowsPerPage(value)
  }

  // ** Function in get data on search query change
  const handleFilter = val => {
    setSearchTerm(val)
    // dispatch(
    //   getData(getDataParameter())
    // )
  }

  // ** Custom Pagination
  const CustomPagination = () => {
    //const count = Number(Math.ceil(store.total / rowsPerPage))

    return (
      <ReactPaginate
        previousLabel={''}
        nextLabel={''}
        pageCount={store.total || 1}
        activeClassName='active'
        forcePage={currentPage !== 0 ? currentPage - 1 : 0}
        onPageChange={page => handlePagination(page)}
        pageClassName={'page-item'}
        nextLinkClassName={'page-link'}
        nextClassName={'page-item next'}
        previousClassName={'page-item prev'}
        previousLinkClassName={'page-link'}
        pageLinkClassName={'page-link'}
        containerClassName={'pagination react-paginate justify-content-end my-2 pe-1'}
      />
    )
  }

  // ** Table data to render
  const dataToRender = () => {
    const filters = {
      q: searchTerm
    }

    const isFiltered = Object.keys(filters).some(function (k) {
      return filters[k].length > 0
    })

    if (store.data.length > 0) {
      return store.data
    } else if (store.data.length === 0 && isFiltered) {
      return []
    } else {
      return store.allData.slice(0, rowsPerPage)
    }
  }

  const handleSort = (column, sortDirection) => {
    setSort(sortDirection)
    setSortColumn(column.sortField)
    dispatch(
      getData(getDataParameter())
    )
  }

  return (
    <Fragment>
      <Card hidden>
        <CardHeader>
          <CardTitle tag='h4'>Filtreler</CardTitle>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md='3'>
              <Label for='role-select'>Grup Adı</Label>
              <Input name='text' id='username' rows='3' placeholder=''
                className='react-select'
                onChange={data => {
                  setSearchGroupName(data.target.value)
                }} />
            </Col>
            <Col className='my-md-0 my-1' md='3'>
              <Label for='plan-select'>Firma Adı</Label>
              <Input name='text' id='username' rows='3' placeholder=''
                className='react-select'
                onChange={data => {
                  setSearchName(data.target.value)
                }} />
            </Col>
            <Col md='3'>
              <Label for='status-select'>Sicil Numarası</Label>
              <Input name='text' id='username' rows='3' placeholder=''
                className='react-select'
                onChange={data => {
                  setSearchRegistrationNumber(data.target.value)
                }} />
            </Col>
            <Col md='3'>
              <Label for='status-select'>Vergi Numarası</Label>
              <Input name='text' id='username' rows='3' placeholder=''
                className='react-select'
                onChange={data => {
                  setSearchTaxNumber(data.target.value)
                }} />
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card className='overflow-hidden'>
        <div className='react-dataTable'>
          <DataTable
            noHeader
            subHeader
            sortServer
            pagination
            responsive
            paginationServer
            columns={columns(isIsyeriDropdownOpen, toggleIsyeriDropdown, isKanunDropdownOpen, toggleKanunDropdown, isDonemDropdownOpen, toggleDonemDropdown)}
            onSort={handleSort}
            sortIcon={<ChevronDown />}
            className='react-dataTable'
            paginationComponent={CustomPagination}
            data={dataToRender()}
            subHeaderComponent={
              <CustomHeader
                store={store}
                searchTerm={searchTerm}
                rowsPerPage={rowsPerPage}
                handleFilter={handleFilter}
                handlePerPage={handlePerPage}
                toggleSidebar={toggleSidebar}
              />
            }
          />
        </div>
      </Card>
    </Fragment>
  )
}

export default UsersList
