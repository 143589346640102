const isStaffStateStyles = {
  color: '#f18e8f',
  backgroundColor: '#fce4e4'
}

//Taşan verileri truncate eden fonksiyon
const truncate = (str, n) => {
  return (str.length > n) ? `${str.slice(0, n - 1)  }...` : str
}

//Truncate edilecek verilerde kullanılacak style
const truncateStyle = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '200px'
}

export const workAccidentsTableColumns = [
    // {
    //   name: 'Grup',
    //   sortable: false,
    //   minWidth: '120px',
    //   sortField: 'companyGroupName',
    //   selector: row => row.companyGroupName,
    //   cell: row => row.companyGroupName,
    //   conditionalCellStyles: [
    //     {
    //       when: row => row.isStaffState,
    //       style: { backgroundColor: "#fce4e4", color: "#f18e8f" }
    //     }
    //   ],
    //   style: ({ backgroundColor: "inerit", color: "inerit" })
    // },
    {
      name: 'İşyeri Adı ve Sicil No',
      sortable: false,
      minWidth: '250px',
      sortField: 'name',
      selector: row => row.registrationNumber,
      cell: row => (
          <div className='d-flex justify-content-left align-items-center'>
              <div className='d-flex flex-column'>
                  <p className='user_name text-truncate text-body mb-0'>
                     <span className="fw-bolder" style={row.isStaffState ? {...isStaffStateStyles, ...truncateStyle} : truncateStyle}>{truncate(row.companyName, 20)}</span>
                  </p>
                  <small className='text-truncate mb-0' style={row.isStaffState ? isStaffStateStyles : null}>{row.registrationNumber}</small>
              </div>
          </div>
      ),
      conditionalCellStyles: [
        {
          when: row => row.isStaffState,
          style: { backgroundColor: "#fce4e4", color: "#f18e8f" }
        }
      ],
      style: ({ backgroundColor: "inerit", color: "inerit" })
  },
    {
      name: 'Ad Soyad',
      sortable: false,
      minWidth: '250px',
      sortField: 'workerName',
      selector: row => row.workerName,
      cell: row => (
        <div className='d-flex justify-content-left align-items-center me-2'>
            <div className='d-flex flex-column'>
                <p className='user_name text-truncate text-body mb-0'>
                    <span className='fw-bolder' style={row.isStaffState ? isStaffStateStyles : null}>{row.workerName}</span>
                </p>
                <small className='text-truncate mb-0' style={row.isStaffState ? isStaffStateStyles : null}>{row.identificationNo}</small>
            </div>
        </div>
    ),
    conditionalCellStyles: [
      {
        when: row => row.isStaffState,
        style: { backgroundColor: "#fce4e4", color: "#f18e8f" }
      }
    ],
    style: ({ backgroundColor: "inerit", color: "inerit" })
    },
    {
      name: 'Pers.Değil',
      minWidth: '150px',
      sortable: false,
      sortField: 'notStaff',
      selector: row => row.notStaff,
      cell: row => row.notStaff,
    conditionalCellStyles: [
      {
        when: row => row.isStaffState,
        style: { backgroundColor: "#fce4e4", color: "#f18e8f" }
      }
    ],
    style: ({ backgroundColor: "inerit", color: "inerit" })
    },
    {
      name: 'Vaka',
      minWidth: '150px',
      sortable: false,
      sortField: 'visitCaseName',
      selector: row => row.visitCaseName,
      cell: row => row.visitCaseName,
      conditionalCellStyles: [
        {
          when: row => row.isStaffState,
          style: { backgroundColor: "#fce4e4", color: "#f18e8f" }
        }
      ],
      style: ({ backgroundColor: "inerit", color: "inerit" })
    },
    {
      name: 'Rapor No',
      maxWidth: '300px',
      minWidth: '250px',
      sortable: false,
      sortField: 'visitReportNumber',
      selector: row => row.visitReportNumber,
      cell: row => (
              <p className='rounded-pill mb-0' style={{ backgroundColor: '#f8d7da', color: '#b71c1c', padding:'0 5px'}}>
                {row.visitReportNumber}
              </p>
    ),
    conditionalCellStyles: [
      {
        when: row => row.isStaffState,
        style: { backgroundColor: "#fce4e4", color: "#f18e8f" }
      }
    ],
    style: ({ backgroundColor: "inerit", color: "inerit" })
    },
    {
      name: 'Kaza Numarası',
      minWidth: '150px',
      sortable: false,
      sortField: 'accidentNo',
      selector: row => row.accidentNo,
      cell: row => row.accidentNo,
      conditionalCellStyles: [
        {
          when: row => row.isStaffState,
          style: { backgroundColor: "#fce4e4", color: "#f18e8f" }
        }
      ],
      style: ({ backgroundColor: "inerit", color: "inerit" })
    },
    {
      name: 'Kaza Tarihi',
      minWidth: '150px',
      sortable: false,
      sortField: 'accidentDate',
      selector: row => row.accidentDate,
      cell: row => row.accidentDate && row.accidentDate,
      conditionalCellStyles: [
        {
          when: row => row.isStaffState,
          style: { backgroundColor: "#fce4e4", color: "#f18e8f" }
        }
      ],
      style: ({ backgroundColor: "inerit", color: "inerit" })
    },
    {
      name: 'Kaza İl / İlçe',
      minWidth: '150px',
      sortable: false,
      sortField: 'dayOff',
      selector: row => `${row.city  } / ${  row.town}`,
      cell: row => (
        <div className='d-flex justify-content-left align-items-center me-2'>
            <div className='d-flex flex-column'>
                <small className='text-wrap mb-0'>{row.city} / {row.town}</small>
            </div>
        </div>
    ),
    conditionalCellStyles: [
      {
        when: row => row.isStaffState,
        style: { backgroundColor: "#fce4e4", color: "#f18e8f" }
      }
    ],
    style: ({ backgroundColor: "inerit", color: "inerit" })
  }
    
  ]
  