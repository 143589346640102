// ** React Imports
import React, { useEffect, useState, useCallback, useMemo} from 'react'

// ** Redux Imports
import { useDispatch, useSelector} from 'react-redux'
import {getWorkerEntryOutDetails} from '../../store/index'

// ** Third Party Components
import {Card} from "reactstrap"
import DataTable from 'react-data-table-component'

// ** moment Import For Date Formatting
import moment from 'moment/moment'

// ** Columns
import { entryOutTableColumns } from './EntryOutTableColumns'

// ** Styles
import '@styles/react/libs/tables/react-dataTable-component.scss'


function  EntryOutTable({workerData}) {
    const {selectedCompanyGroup} = useSelector(state => state.auth)
    const [entryOutTableData, setEntryOutTableData] = useState(null)
    const dispatch = useDispatch()
    const { active, detailTabData } = useSelector(state => state.appTabsManagement)

    // ** Query params for distpatch
    const memoizedQuery = useMemo(() => ({
      CompanyGroupId: selectedCompanyGroup,
      IdentificationNumber: workerData?.identityNumber,
      SortColumn: 'startDate',
      Sort: 'desc'
    }), [selectedCompanyGroup, workerData?.identityNumber])
  
    // ** Re-render etmemek için callback kullandık
    const fetchWorkerEntryOutDetails = useCallback(async () => {
      try {
        const action = await dispatch(getWorkerEntryOutDetails(memoizedQuery)).unwrap()
        setEntryOutTableData(action)
      } catch (error) {
        console.error('Failed to fetch worker entry out details:', error)
      }
    }, [dispatch, memoizedQuery])
  
    // ** Get data on mount
    useEffect(() => {
      fetchWorkerEntryOutDetails()
    }, [active, detailTabData, fetchWorkerEntryOutDetails]) 

    // ** Re-render etmemek için memoize ettik
    const memoizedNewData = useMemo(() => {
      return entryOutTableData?.map((item, index) => {
        const startDate = moment(item.startDate, 'DD.MM.YYYY')
        const endDate = item.endDate ? moment(item.endDate, 'DD.MM.YYYY') : moment()
        const endDateToRender = item.endDate ? item.endDate : 'AKTİF'
        const days = endDate.diff(startDate, 'days')
  
        return {
          ...item,
          index,
          days,
          nameSurname: `${item.firstname} ${item.lastname}`,
          endDate: endDateToRender
        }
      }) || []
    }, [entryOutTableData])
  
    return (
          <Card className='overflow-hidden'>
              <div className='react-dataTable' style={{height: 480, width: '100%'}}>
                  {memoizedNewData && (
                     <DataTable
                        noHeader
                        responsive
                        columns={entryOutTableColumns}
                        className='react-dataTable h-100'
                        data={memoizedNewData}
                        fixedHeader
                        fixedHeaderScrollHeight="500px" 
                   />

                  )}
              </div>
          </Card>
  )
}

export default EntryOutTable