// // ** React Imports
import { store } from "@store/store"
import { Eye, Settings, Trash } from "react-feather"
import Select from "react-select"
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap"
import { approveVisit, detailPopupState, getCompanyDetails, ToggleVisitHtmlImagePopup, DeleteLossVisit } from "../store"
import { createWorkerDetailsTab } from "../../workerlist/store"
import toast from "react-hot-toast"

export const columns = (approveSelectedList = []) => [
  {
    name: "Kod",
    sortable: true,
    minWidth: "150px",
    sortField: "CompanyName",
    selector: row => row.companyName,
    cell: row => row.companyName,
    conditionalCellStyles: [
      {
        when: row => row.isStaffState,
        style: { backgroundColor: "#fce4e4", color: "#f18e8f" }
      }
    ],
    style: ({ backgroundColor: "inerit", color: "inerit" })
  },
  {
    name: "İşyeri",
    sortable: true,
    minWidth: "350px",
    sortField: "WorkplaceCode",
    selector: row => row.workplaceCode,
    //cell: row => row.workplaceCode
    cell: (row) => <button className="btn btn-danger"
                           onClick={() => store.dispatch(getCompanyDetails({
                             id: row.companyId,
                             name: "FirmaDetay1",
                             text: row.companyName
                           }))}
                           id={row.workplaceCode}>{row.workplaceCode}</button>,
    button: true,
    conditionalCellStyles: [
      {
        when: row => row.isStaffState,
        style: { backgroundColor: "#fce4e4", color: "#f18e8f" }
      }
    ],
    style: ({ backgroundColor: "inerit", color: "#fffff" })
  },
  {
    name: "T.C.",
    sortable: true,
    minWidth: "200px",
    sortField: "IdentificationNumber",
    selector: row => row.identificationNumber,
    cell: (row) => <button className="btn btn-danger" id={row.identificationNumber} onClick={() => (row.companyWorkerLogId ? store.dispatch(createWorkerDetailsTab(
      {
          id: row.companyWorkerLogId,
          name: "CalisanDetayi",
          text:`${row.name  } ${  row.surname}`
      }
    )) : toast.error("Çalışan detayı bulunamadı"))
} >{row.identificationNumber}</button>,
    button: true,
    conditionalCellStyles: [
      {
        when: row => row.isStaffState,
        style: { backgroundColor: "#fce4e4", color: "#f18e8f" }
      }
    ],
    style: ({ backgroundColor: "inerit", color: "#fffff" })
  },
  {
    name: "Ad",
    sortable: true,
    minWidth: "200px",
    sortField: "Name",
    selector: row => row.name,
    cell: row => row.name,
    conditionalCellStyles: [
      {
        when: row => row.isStaffState,
        style: { backgroundColor: "#fce4e4", color: "#f18e8f" }
      }
    ],
    style: ({ backgroundColor: "inerit", color: "inerit" })
  },
  {
    name: "Soyad",
    sortable: true,
    minWidth: "150px",
    sortField: "Surname",
    selector: row => row.surname,
    cell: row => row.surname,
    conditionalCellStyles: [
      {
        when: row => row.isStaffState,
        style: { backgroundColor: "#fce4e4", color: "#f18e8f" }
      }
    ],
    style: ({ backgroundColor: "inerit", color: "inerit" })
  },
  {
    name: "Vaka",
    sortable: true,
    minWidth: "150px",
    sortField: "case",
    selector: row => row.case,
    cell: row => row.case,
    conditionalCellStyles: [
      {
        when: row => row.isStaffState,
        style: { backgroundColor: "#fce4e4", color: "#f18e8f" }
      }
    ],
    style: ({ backgroundColor: "inerit", color: "inerit" })
  },
  {
    name: "Pers.Degil",
    sortable: false,
    minWidth: "150px",
    sortField: "notEmploye",
    selector: row => row.notEmployee,
    cell: row => row.notEmployee,
    conditionalCellStyles: [
      {
        when: row => row.isStaffState,
        style: { backgroundColor: "#fce4e4", color: "#f18e8f" }
      }
    ],
    style: ({ backgroundColor: "inerit", color: "inerit" })
  },
  {
    name: "Ceza",
    sortable: true,
    minWidth: "100px",
    sortField: "punishment",
    selector: row => row.penalty,
    cell: row => row.penalty,
    conditionalCellStyles: [
      {
        when: row => row.isStaffState,
        style: { backgroundColor: "#fce4e4", color: "#f18e8f" }
      }
    ],
    style: ({ backgroundColor: "inerit", color: "inerit" })
  },
  {
    name: "Rapor No",
    sortable: true,
    minWidth: "275px",
    sortField: "reportNo",
    selector: row => row.reportNo,
    cell: (row) => <button className="btn btn-danger" id={row.reportNo}
                           onClick={() => store.dispatch(ToggleVisitHtmlImagePopup({
                             show: true,
                             image: `${row.visitHtmlImage}`,
                             visitReportNumber: row.reportNo.split("-")[1]
                           }))}>{row.reportNo}</button>,
    conditionalCellStyles: [
      {
        when: row => row.isStaffState,
        style: { backgroundColor: "#fce4e4", color: "#f18e8f" }
      }
    ],
    style: ({ backgroundColor: "inerit", color: "inerit" })
  },
  {
    name: "Rapor Baş.",
    sortable: true,
    minWidth: "150px",
    sortField: "reportStartDate",
    selector: row => row.reportStartDate,
    cell: row => row.reportStartDate,
    conditionalCellStyles: [
      {
        when: row => row.isStaffState,
        style: { backgroundColor: "#fce4e4", color: "#f18e8f" }
      }
    ],
    style: ({ backgroundColor: "inerit", color: "inerit" })
  },
  {
    name: "Rapor Bit.",
    sortable: true,
    minWidth: "150px",
    sortField: "reportEndDate",
    selector: row => row.reportEndDate,
    cell: row => row.reportEndDate,
    conditionalCellStyles: [
      {
        when: row => row.isStaffState,
        style: { backgroundColor: "#fce4e4", color: "#f18e8f" }
      }
    ],
    style: ({ backgroundColor: "inerit", color: "inerit" })
  },
  {
    name: "Ayakta",
    sortable: true,
    minWidth: "100px",
    sortField: "standing",
    selector: row => row.standing,
    cell: row => row.standing,
    conditionalCellStyles: [
      {
        when: row => row.isStaffState,
        style: { backgroundColor: "#fce4e4", color: "#f18e8f" }
      }
    ],
    style: ({ backgroundColor: "inerit", color: "inerit" })
  },
  {
    name: "İzin Günü",
    sortable: true,
    minWidth: "150px",
    sortField: "dayOff",
    selector: row => row.dayOff,
    cell: row => row.dayOff,
    conditionalCellStyles: [
      {
        when: row => row.isStaffState,
        style: { backgroundColor: "#fce4e4", color: "#f18e8f" }
      }
    ],
    style: ({ backgroundColor: "inerit", color: "inerit" })
  },
  {
    name: "İzin ÇG",
    sortable: true,
    // minWidth: '100px',
    sortField: "dayOffWorkDay",
    selector: row => row.dayOffWorkDay,
    cell: row => row.dayOffWorkDay,
    conditionalCellStyles: [
      {
        when: row => row.isStaffState,
        style: { backgroundColor: "#fce4e4", color: "#f18e8f" }
      }
    ],
    style: ({ backgroundColor: "inerit", color: "inerit" })
  },
  {
    name: "Cari Etki",
    sortable: true,
    minWidth: "150px",
    sortField: "currentImpact",
    selector: row => row.currentImpact,
    cell: row => row.currentImpact,
    conditionalCellStyles: [
      {
        when: row => row.isStaffState,
        style: { backgroundColor: "#fce4e4", color: "#f18e8f" }
      }
    ],
    style: ({ backgroundColor: "inerit", color: "inerit" })
  },
  {
    name: "Ekran Düşme",
    sortable: true,
    minWidth: "175px",
    sortField: "policlinicDate",
    selector: row => row.policlinicDate,
    cell: row => row.policlinicDate,
    conditionalCellStyles: [
      {
        when: row => row.isStaffState,
        style: { backgroundColor: "#fce4e4", color: "#f18e8f" }
      }
    ],
    style: ({ backgroundColor: "inerit", color: "inerit" })
  },
  {
    name: "Sist. Eklenme",
    sortable: true,
    minWidth: "175px",
    sortField: "systemDate",
    selector: row => row.systemDate,
    cell: row => row.systemDate,
    conditionalCellStyles: [
      {
        when: row => row.isStaffState,
        style: { backgroundColor: "#fce4e4", color: "#f18e8f" }
      }
    ],
    style: ({ backgroundColor: "inerit", color: "inerit" })
  },
  {
    name: "Açıklama",
    sortable: false,
    minWidth: "350px",
    sortField: "explanation",
    selector: row => row.explanation,
    cell: row => (
      <>
        {
          !row.isApproved || row.reportEndDate === "-" ? "Rapor onaylanamaz" : row.explanation
        }
      </>),
    conditionalCellStyles: [
      {
        when: row => row.isStaffState,
        style: { backgroundColor: "#fce4e4", color: "#f18e8f" }
      }
    ],
    style: ({ backgroundColor: "inerit", color: "inerit" })
  },
  {
    name: "Onaylama",
    sortable: false,
    minWidth: "150px",
    sortField: "currentImpact",
    selector: row => row.currentImpact,
    cell: row => (
      <>{row.visitType === 4 ? "Kayıp Vizite" : !row.isApproved || row.reportEndDate === "-" ? null : row.isStaffState ? <Select
          isClearable={false}
          options={[{ value: -1, label: "Seçiniz" }, { value: "2", label: `Personel Değil` }]}
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          menuPortalTarget={document.body}
          className="react-select"
          classNamePrefix="select"
          onChange={data => {
            store.dispatch(approveVisit({
              qualityState: data.value,
              identificationNumber: row.identificationNumber,
              case: row.case,
              medulaReportId: row.medularReportId,
              date: row.reportEndDate,
              companyId: 13,
              visitId: row.visitId
            }))
          }}
        /> : <Select
          isClearable={false}
          options={row.approveSelectList}
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          menuPortalTarget={document.body}
          className="react-select"
          classNamePrefix="select"
          value={row.approveSelectList.filter(x => approveSelectedList.includes(x.value)).length === 0 ? {
            label: "Seçiniz",
            value: `0-${row.identificationNumber}`
          } : row.approveSelectList.filter(x => approveSelectedList.includes(x.value))[0]}
          onChange={data => {
            store.dispatch(approveVisit({
              qualityState: data.value,
              identificationNumber: row.identificationNumber,
              case: row.case,
              medulaReportId: row.medularReportId,
              date: row.reportEndDate,
              companyId: 13,
              visitId: row.visitId
            }))
          }}
        />}
      </>

    ),
    conditionalCellStyles: [
      {
        when: row => row.isStaffState,
        style: { backgroundColor: "#fce4e4", color: "#f18e8f" }
      }
    ],
    style: ({ backgroundColor: "inerit", color: "inerit" })
  },
  {
    name: "İşlemler",
    minWidth: "150px",
    sortable: false,
    cell: row => (
      <div className="column-action">
        <UncontrolledDropdown>
          <DropdownToggle tag="div" className="btn btn-sm">
            <Settings size={14} className="cursor-pointer" />
          </DropdownToggle>
          <DropdownMenu container="body">
            <DropdownItem
              tag="a"
              href="/"
              id={row.id}
              className="w-100"
              onClick={e => {
                e.preventDefault()
                store.dispatch(detailPopupState({ tckn: row.identificationNumber, popupStatus: true }))
              }}
            >
              <Eye size={14} className="me-50" />
              <span className="align-middle">Detay</span>
            </DropdownItem>
            {
              row.visitType === 4 ? <DropdownItem
                tag="a"
                href="/"
                id={row.id}
                className="w-100"
                onClick={e => {
                  e.preventDefault()
                  store.dispatch(DeleteLossVisit(row.visitId))
                }}
              >
                <Trash size={14} className="me-50" />
                <span className="align-middle">Sil</span>
              </DropdownItem> : null
            }
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    ),
    conditionalCellStyles: [
      {
        when: row => row.isStaffState,
        style: { backgroundColor: "#fce4e4", color: "#f18e8f" }
      }
    ],
    style: ({ backgroundColor: "inerit", color: "inerit" })
  }

]

