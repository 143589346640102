// ** React Imports
import React, { useEffect, useState, useCallback, useMemo} from 'react'

// ** Redux Imports
import { useDispatch, useSelector} from 'react-redux'
import {getWorkerVisitDetails} from '../../store/index'

// ** Third Party Components
import {Card} from "reactstrap"
import DataTable from 'react-data-table-component'

// ** Columns
import { visitsTableColumns } from './VisitsTableColumns'

// ** Styles
import '@styles/react/libs/tables/react-dataTable-component.scss'

// ** Permission Control
import { getModuleIdsByCompanyGroupId } from '../../../../../auth/utils'


function  VisitsTable({workerData}) {
    const {selectedCompanyGroup, userData } = useSelector(state => state.auth)
    const [visitsTableData, setVisitsTableData] = useState(null)
    const dispatch = useDispatch()
    const moduleIds = getModuleIdsByCompanyGroupId(userData, selectedCompanyGroup)
    const hasPermission = moduleIds.includes(6) 
    const { detailTabData } = useSelector(state => state.appTabsManagement)

    // ** Query params for distpatch
  const memoizedQuery = useMemo(() => ({
    CompanyGroupId: selectedCompanyGroup,
    IdentificationNumber: workerData?.identityNumber
  }), [selectedCompanyGroup, workerData?.identityNumber])

    // ** Re-render etmemek için callback kullandık
    const fetchWorkerEntryOutDetails = useCallback(async () => {
    try {
      const action = await dispatch(getWorkerVisitDetails(memoizedQuery)).unwrap()
      setVisitsTableData(action)
    } catch (error) {
      console.error('Failed to fetch worker entry out details:', error)
    }
  }, [dispatch, memoizedQuery])

    // ** Get data on mount
  useEffect(() => {
    fetchWorkerEntryOutDetails()
  }, [fetchWorkerEntryOutDetails, detailTabData]) // Ensure correct dependencies

    // ** Re-render etmemek için memoize ettik
  const mappedData = useMemo(() => {
    return visitsTableData?.map((item, index) => ({
      ...item,
      index,
      nameSurname: `${item.firstname} ${item.lastname}`
    })) || []
  }, [visitsTableData])

    if (!hasPermission) {
        return (
            <Card className='overflow-hidden'>
            <div className='react-dataTable' style={{height: 480, width: '100%'}}>
                <div className='d-flex justify-content-center align-items-center'>
                    <h4 className='rounded-pill mb-0' style={{ backgroundColor: '#f8d7da', color: '#b71c1c', padding:'5px 10px'}}>
                        Vizite Modülü Kullanılmamaktadır
                    </h4>
                </div>
            </div>
        </Card> 
        )
    }    

  return (
          <Card className='overflow-hidden'>
              <div className='react-dataTable' style={{height: 480, width: '100%'}}>
                     <DataTable
                        noHeader
                        responsive
                        columns={visitsTableColumns}
                        className='react-dataTable h-100'
                        data={mappedData}
                        fixedHeader
                        fixedHeaderScrollHeight="500px" 
                   />
              </div>
          </Card>
  )
}

export default VisitsTable