import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DataGrid } from '@mui/x-data-grid'
import { Box, Button, Typography, CircularProgress, alpha } from '@mui/material'
import { getQueueCompanyStatusData, showQueueCompanyStatusData } from '../store'
import { FiGrid, FiRefreshCw } from 'react-icons/fi'
import { PiQueue } from "react-icons/pi"

const QueueCompanyStatus = () => {
  const dispatch = useDispatch()
  const loading = useSelector(state => state.queue.queueCompanyStatusDataLoading)
  const queueCompanyStatusData = useSelector(state => state.queue.queueCompanyStatusData)

  useEffect(() => {
    dispatch(getQueueCompanyStatusData({ schedulerId: 0 }))
  }, [dispatch])

  const columns = [
    {
      field: 'companyGroupName',
      headerName: 'Grup Adı',
      width: 250,
      headerAlign: 'left',
      align: 'left'
    },
    {
      field: 'total',
      headerName: 'Toplam Kuyruk Sayısı',
      width: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <CustomCell color="#1976d2" value={params.value} />
      )
    },
    {
      field: 'successSubschedulerCount',
      headerName: 'Başarılı Kuyruk Sayısı',
      width: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <CustomCell color="#4caf50" value={params.value} />
      )
    },
    {
      field: 'waitingSubschedulerCount',
      headerName: 'Bekleyen Kuyruk Sayısı',
      width: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <CustomCell color="#ff9800" value={params.value} />
      )
    },
    {
      field: 'errorSubschedulerCount',
      headerName: 'Hatalı Kuyruk Sayısı',
      width: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <CustomCell color="#f44336" value={params.value} />
      )
    },
    {
      field: 'actions',
      headerName: 'Şirket Kuyrukları',
      width: 200,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Button
          variant="outlined"
          size="small"
          onClick={() => dispatch(showQueueCompanyStatusData({
            id: params.row.id,
            name: 'FirmaKuyrukDurumu',
            text: params.row.companyGroupName
          }))}
          sx={{
            minWidth: '140px',
            height: '32px',
            color: '#1976d2',
            backgroundColor: alpha('#1976d2', 0.04),
            borderColor: alpha('#1976d2', 0.2),
            textTransform: 'none',
            fontSize: '0.75rem',
            fontWeight: 600,
            letterSpacing: '0.3px',
            transition: 'all 0.2s ease',
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            '&:hover': {
              backgroundColor: alpha('#1976d2', 0.1),
              borderColor: alpha('#1976d2', 0.3)
            }
          }}
        >
          <PiQueue size={16} />
          Şirket Kuyrukları
        </Button>
      )
    }
  ]

  const rows = queueCompanyStatusData.map(item => ({
    id: item.companyGroupId,
    companyGroupId: item.companyGroupId,
    companyGroupName: item.companyGroupName,
    total: item.total,
    successSubschedulerCount: item.successSubschedulerCount,
    waitingSubschedulerCount: item.waitingSubschedulerCount,
    errorSubschedulerCount: item.errorSubschedulerCount,
    subSchedulers: item.subSchedulers
  }))

  return (
    <Box sx={{
      p: 3,
      backgroundColor: '#fff',
      borderRadius: 2,
      boxShadow: '0 4px 20px rgba(0,0,0,0.1)'
    }}>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 2
      }}>
        <Typography
          variant="h5"
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            fontWeight: 600
          }}
        >
          <PiQueue />
          Grup Kuyruk Durumu Tablosu
        </Typography>
        <Button
          variant="outlined"
          startIcon={<FiRefreshCw />}
          onClick={() => dispatch(getQueueCompanyStatusData({ schedulerId: 0 }))}
          sx={{
            textTransform: 'none',
            borderRadius: '15px !important',
            color: '#766AF1',
            borderColor: '#766AF1'
          }}
        >
          Yenile
        </Button>

      </Box>

      {loading ? (
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 600
        }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ height: 650, width: '100%' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5, 10, 20]}
            disableSelectionOnClick
            sx={{
              border: 'none',
              '& .MuiDataGrid-cell': {
                borderColor: alpha('#000', 0.05)
              },
              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: alpha('#000', 0.02),
                borderColor: alpha('#000', 0.05)
              },
              '& .MuiDataGrid-row:hover': {
                backgroundColor: alpha('#000', 0.03)
              }
            }}
          />
        </Box>
      )}
    </Box>
  )
}

const CustomCell = ({ color, value }) => (
  <Box sx={{
    backgroundColor: alpha(color, 0.1),
    color,
    width: '100%',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '12px',
    fontWeight: '600'
  }}>
    {value}
  </Box>
)

export default QueueCompanyStatus
