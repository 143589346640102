import React from 'react'
import { useSelector } from 'react-redux'
import CompanyList from './company-list'

const WorkforceGraph = () => {
    // eslint-disable-next-line no-unused-vars
    const selectedCompanyGroup = useSelector(state => state.auth.selectedCompanyGroup)
         return (
        <>
        <CompanyList />
        </>
    )
}

export default WorkforceGraph