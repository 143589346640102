// ** Redux Imports
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"
import toast from "react-hot-toast"

export const getData = createAsyncThunk("companies/getData", async params => {
    const url = `/api/companies`
    const response = await axios.get(url, {params})
    return {
        params,
        data: response.data.companies,
        totalPages: response.data.pageCount
    }
})

export const setSendButtonDisabled = createAsyncThunk("api/setSendButtonDisabled", async (status) => {
    return status
})

export const getXmlData = createAsyncThunk("companies/getXmlData", async (data, { }) => {
    try {
        const response = await axios.get("/api/Iskur/export-xml", {
            params: {
                companyId: data.companyId,
                year: data.year,
                month: data.month
            },
            responseType: 'blob'
        })

        const file = new Blob([response.data], { type: 'application/xml' })
        const fileURL = URL.createObjectURL(file)

        const fileName = `${data.companyName}-${data.year}-${data.month}-ISGUCUCIZELGE.xml`

        const link = document.createElement('a')
        link.href = fileURL
        link.download = fileName
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

    } catch (error) {
        console.error("XML dosyası indirilirken bir hata oluştu:", error)
        toast.error("XML dosyası indirilirken bir hata oluştu. Lütfen tekrar deneyin.")
    }
})


export const getCompanyGroupData = createAsyncThunk("companies/getCompanyGroupData", async () => {
    const url = `/api/companygroup?PageSize=1000&page=1`
    const response = await axios.get(url)
    const selectData = []
    selectData.push({label: "Seçiniz", value: "0"})
    response.data.companyGroups.map((item) => {
        selectData.push({label: item.name, value: item.id})
    })

    return selectData
})

export const setCompanyGroupId = createAsyncThunk("api/deleteCompanies", async (id) => {
    return id
})
export const modalShow = createAsyncThunk("api/modalShow", async (id) => {
    return id
})

export const addTotallyCompanyAddControl = createAsyncThunk("appCompanies/control", async (companydata, {
        dispatch,
        getState
    }) => {
        await dispatch(setSendButtonDisabled(true))
        const fileData = getState().company.controlfile
        const periodData = getState().company.companyGroupId
        const companyGroupName = companydata.newCompanyGroupName


        const data = new FormData()
        if (fileData) data.append("file", fileData, fileData.name)
        data.append("companyGroupId", periodData || 0)
        data.append("newCompanyGroupName", companyGroupName || "")
        const response = await axios.post(`api/companies/control`, data)
        if (response.status === 200) {
            await dispatch(getData(getState().company.params))
            toast.success("Kayıt Başarılı Bir Şekilde Yapıldı.")
        }
        await dispatch(setSendButtonDisabled(false))
        return response

    }
)
export const iskurCompanySlice = createSlice({
    name: "iskurCompanies",
    initialState: {
        data: [],
        total: 1,
        params: {},
        companyGroupSelectData: [],
        selectedCompany: null,
        companyGroupId: 0,
        disabledSendButton: false,
        xmlExportPopupShow: false
    },

    reducers: {
        setXmlExportPopup: (state, action) => {
            state.xmlExportPopupShow = action.payload.show
            state.selectedCompany = action.payload
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data
                state.params = action.payload.params
                state.total = action.payload.totalPages
            })
            .addCase(getCompanyGroupData.fulfilled, (state, action) => {
                state.companyGroupSelectData = action.payload
            })
            .addCase(setCompanyGroupId.fulfilled, (state, action) => {
                state.companyGroupId = action.payload
            })
            .addCase(modalShow.fulfilled, (state, action) => {
                state.passwordChangeCompanyId = action.payload
                state.addPasswordShow = true
            })
            .addCase(setSendButtonDisabled.fulfilled, (state, action) => {
                state.disabledSendButton = action.payload
            })


    }
})
export const { setXmlExportPopup } = iskurCompanySlice.actions

export default iskurCompanySlice.reducer
