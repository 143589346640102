import { lazy } from 'react'

const QueuePerformance = lazy(() => import('../../views/pages/queue/queue-performance/index'))
const QueueCompanyStatus = lazy(() => import('../../views/pages/queue/queue-company-status/index'))

const QueueRoutes = [
  {
    path: '/kuyruk-performansi',
    element: <QueuePerformance />
  },
  {
    path: '/firma-kuyruk-durumu',
    element: <QueueCompanyStatus />
  }
]

export default QueueRoutes
