const isStaffStateStyles = {
  color: '#f18e8f',
  backgroundColor: '#fce4e4'
}

export const visitsTableColumns = [
    {
      name: 'Grup',
      sortable: false,
      minWidth: '100px',
      sortField: 'companyGroupName',
      selector: row => row.companyGroupName,
      cell: row => row.companyGroupName,
      conditionalCellStyles: [
        {
          when: row => row.isStaffState,
          style: { backgroundColor: "#fce4e4", color: "#f18e8f" }
        }
      ],
      style: ({ backgroundColor: "inerit", color: "inerit" })
    },
    {
      name: 'İşyeri Adı ve Sicil No',
      sortable: false,
      minWidth: '250px',
      sortField: 'name',
      selector: row => row.registirationNumber,
      cell: row => (
          <div className='d-flex justify-content-left align-items-center'>
              <div className='d-flex flex-column'>
                  <p className='user_name text-truncate text-body mb-0'>
                     <span className="fw-bolder" style={row.isStaffState ? isStaffStateStyles : null}>{row.companyName}</span>
                  </p>
                  <small className='text-truncate mb-0' style={row.isStaffState ? isStaffStateStyles : null}>{row.registirationNumber}</small>
              </div>
          </div>
      ),
      conditionalCellStyles: [
        {
          when: row => row.isStaffState,
          style: { backgroundColor: "#fce4e4", color: "#f18e8f" }
        }
      ],
      style: ({ backgroundColor: "inerit", color: "inerit" })
  },
    {
      name: 'Ad Soyad',
      sortable: false,
      minWidth: '250px',
      sortField: 'nameSurname',
      selector: row => row.nameSurname,
      cell: row => (
        <div className='d-flex justify-content-left align-items-center me-2'>
            <div className='d-flex flex-column'>
                <p className='user_name text-truncate text-body mb-0'>
                    <span className='fw-bolder' style={row.isStaffState ? isStaffStateStyles : null}>{row.nameSurname}</span>
                </p>
                <small className='text-truncate mb-0' style={row.isStaffState ? isStaffStateStyles : null}>{row.identificationNumber}</small>
            </div>
        </div>
    ),
    conditionalCellStyles: [
      {
        when: row => row.isStaffState,
        style: { backgroundColor: "#fce4e4", color: "#f18e8f" }
      }
    ],
    style: ({ backgroundColor: "inerit", color: "inerit" })
    },
    {
      name: 'Pers.Değil',
      minWidth: '75px',
      sortable: false,
      sortField: 'staffState1',
      selector: row => row.notEmployee,
    cell: row => row.notEmployee,
    conditionalCellStyles: [
      {
        when: row => row.isStaffState,
        style: { backgroundColor: "#fce4e4", color: "#f18e8f" }
      }
    ],
    style: ({ backgroundColor: "inerit", color: "inerit" })
    },
    {
      name: 'Vaka',
      minWidth: '150px',
      sortable: false,
      sortField: 'caseName',
      selector: row => row.caseName,
      cell: row => row.caseName,
      conditionalCellStyles: [
        {
          when: row => row.isStaffState,
          style: { backgroundColor: "#fce4e4", color: "#f18e8f" }
        }
      ],
      style: ({ backgroundColor: "inerit", color: "inerit" })
    },
    {
      name: 'Rapor No',
      maxWidth: '300px',
      minWidth: '250px',
      sortable: false,
      sortField: 'reportNo',
      selector: row => row.reportNo,
      cell: row => (
              <p className='rounded-pill mb-0' style={{ backgroundColor: '#f8d7da', color: '#b71c1c', padding:'0 5px'} }>  
                {row.reportNo}
              </p>
      ),
      conditionalCellStyles: [
        {
          when: row => row.isStaffState,
          style: { backgroundColor: "#fce4e4", color: "#f18e8f" }
        }
      ],
      style: ({ backgroundColor: "inerit", color: "inerit" })
    },
    {
      name: 'Rapor Başlangıç Tarihi',
      minWidth: '150px',
      sortable: false,
      sortField: 'reportStartDate',
      selector: row => row.reportStartDate,
      cell: row => row.reportStartDate && row.reportStartDate.split(' ')[0].trim(),
      conditionalCellStyles: [
        {
          when: row => row.isStaffState,
          style: { backgroundColor: "#fce4e4", color: "#f18e8f" }
        }
      ],
      style: ({ backgroundColor: "inerit", color: "inerit" })
    },
    {
      name: 'Rapor Bitiş Tarihi',
      minWidth: '150px',
      sortable: false,
      sortField: 'reportEndDate',
      selector: row => row.reportEndDate,
      cell: row => row.reportEndDate && row.reportEndDate.split(' ')[0].trim(),
      conditionalCellStyles: [
        {
          when: row => row.isStaffState,
          style: { backgroundColor: "#fce4e4", color: "#f18e8f" }
        }
      ],
      style: ({ backgroundColor: "inerit", color: "inerit" })
    },
    {
      name: 'İzin Günü',
      minWidth: '50px',
      sortable: false,
      sortField: 'dayOff',
      selector: row => row.dayOff,
      cell: row => row.dayOff,
      conditionalCellStyles: [
        {
          when: row => row.isStaffState,
          style: { backgroundColor: "#fce4e4", color: "#f18e8f" }
        }
      ],
      style: ({ backgroundColor: "inerit", color: "inerit" })
    }
  ]
  