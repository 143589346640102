// ** React Imports
import React, {Fragment, useEffect, useState} from 'react'

// ** Reactstrap Imports
import {Button, CardBody, Col, Form, Input, Label, Modal, ModalBody, ModalHeader, Row} from 'reactstrap'
import {Card, IconButton, MenuItem, Select} from '@mui/material'

// ** Third Party Components
import FilePopup from './views/FilePopup'
import SidebarSingularSgkEntry from './views/SingularSgkEntryPopup'
import SingularSgkOutPopup from './views/SingularSgkOutPopup'

// ** Store & Actions
import {useDispatch, useSelector} from 'react-redux'
import {store} from "@store/store"

import {
    getCompanyGroupData,
    getCSGBBusinessDepartmentList,
    getDutyCodeList,
    getCompanyList,
    getInsuranceTypesList,
    getMissingDayReasonList,
    getReasonForLeavingList,
    getSgkEducationList,
    getWorkerOutDocumentTypesList,
    toggleSgkSingularEntryPopupClose,
    toggleSgkSingularOutPopupClose,
    setFilePopupOpen,
    getWorkerLogs,
    downloadLogExcel,
    downloadDeclaration,
    toggleJobCodePopup,
    setJobCodeFilePopupOpen
} from './store'
import {DataGrid, trTR} from "@mui/x-data-grid"
import Pagination from "@mui/material/Pagination"
import PaginationItem from "@mui/material/PaginationItem"
import {BsFiletypeXls, BsFileEarmarkZip} from "react-icons/bs"
import JobCodeUpdatePopup from "./views/JobCodeUpdatePopup"
import JobCodeFilePopup from "./views/JobCodeFilePopup"
// ** Custom Components

const columns = [
    {field: 'id', headerName: 'Sıra No', minWidth: 200, sortable: false, filterable: false},
    {field: 'type', headerName: 'İşlem Türü', minWidth: 200, sortable: false, filterable: false},
    {field: 'date', headerName: 'İşlem Tarihi', minWidth: 200, sortable: false, filterable: false},
    {field: 'companyCount', headerName: 'Sicil Sayısı', minWidth: 200, sortable: false, filterable: false},
    {field: 'workerCount', headerName: 'Personel Sayısı', minWidth: 200, sortable: false, filterable: false},
    {
        field: 'exportExcel',
        headerName: 'Sonuç Rapor',
        minWidth: 200,
        sortable: false,
        filterable: false,
        renderCell: (params) => (
            <>
                <IconButton size='60'
                            onClick={() => store.dispatch(downloadLogExcel(params.row.id))}>
                    <BsFiletypeXls size={30}/>
                </IconButton>
            </>
        )
    },
    {
        field: 'exportDeclaration',
        headerName: 'Bildirge',
        minWidth: 200,
        sortable: false,
        filterable: false,
        renderCell: (params) => (
            <>
                <IconButton size='60'
                            onClick={() => store.dispatch(downloadDeclaration(params.row.id))}>
                    <BsFileEarmarkZip size={30}/>
                </IconButton>
            </>
        )
    }
]

function CustomPagination(props) {
    const handlePageSizeChange = (event) => {
        props.onPageSizeChange(event.target.value)
    }

    const handlePageChange = (event, newPage) => {
        props.onPageChange(newPage - 1)
    }
    return (
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
            <Select
                value={props.pageSize}
                onChange={handlePageSizeChange}
                style={{marginRight: '16px', marginTop: '-10px'}}
            >
                {props.pageSizeOptions.map((size) => (
                    <MenuItem key={size} value={size}>
                        {size}
                    </MenuItem>
                ))}
            </Select>
            <Pagination
                color='primary'
                shape='circular'
                page={props.page + 1}
                count={props.rowCount}
                renderItem={(props2) => <PaginationItem {...props2} disableRipple/>}
                onChange={handlePageChange}
            />
        </div>
    )
}

const RoleCards = () => {
    // ** Store Vars
    const dispatch = useDispatch()
    const storeWorkerList = useSelector(state => state.sgkWorkerDeclaration)
    const authStore = useSelector(state => state.auth)

    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(25)
    const [sort, setSort] = useState('desc')
    const [sortColumn, setSortColumn] = useState('id')

    const getDataParameter = () => {
        return {
            page: page + 1,
            pageSize,
            sort,
            sortColumn,
            companyGroupId: authStore.selectedCompanyGroup
        }
    }

    useEffect(() => {
        dispatch(getWorkerLogs(getDataParameter()))
    }, [])

    const toggleFilePopup = () => dispatch(setFilePopupOpen(!storeWorkerList.filePopupOpen))
    const toggleJobCodeFilePopup = () => dispatch(setJobCodeFilePopupOpen(!storeWorkerList.fileJobCodePopupOpen))

    useEffect(() => {
        dispatch(getSgkEducationList())
        dispatch(getCSGBBusinessDepartmentList())
        dispatch(getDutyCodeList())
        dispatch(getInsuranceTypesList())
        dispatch(getReasonForLeavingList())
        dispatch(getWorkerOutDocumentTypesList())
        dispatch(getMissingDayReasonList())
        dispatch(getCompanyGroupData())
        dispatch(getCompanyList(1))
    }, [])

    useEffect(() => {
        dispatch(getCompanyList(1))
    }, [authStore.selectedCompanyGroup])

    const handleSortModelChange = React.useCallback((sortModel) => {
        // Here you save the data you need from the sort model
        if (sortModel.length > 0) {
            setSortColumn(sortModel[0].field)
            setSort(sortModel[0].sort)
        } else {
            setSortColumn('id')
            setSort('desc')
        }
    }, [])

    // ** States
    return (
        <Fragment>
            <Row>
                <Col xl={4} md={6}>
                    <Card>
                        <Row>
                            <Col>
                                <CardBody className='text-sm text-center ps-sm-0'>
                                    <Button
                                        color='primary'
                                        className='text-nowrap mb-1'
                                        onClick={() => {
                                            dispatch(toggleSgkSingularEntryPopupClose(true))
                                        }}
                                    >
                                        İşe Giriş işlemi
                                    </Button>
                                    <p className='mb-0'>Sgk işe giriş bildirim işlemi</p>
                                </CardBody>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col xl={4} md={6}>
                    <Card>
                        <Row>
                            <Col>
                                <CardBody className='text-sm text-center ps-sm-0'>
                                    <Button
                                        color='primary'
                                        className='text-nowrap mb-1'
                                        onClick={e => {
                                            e.preventDefault()
                                            dispatch(toggleSgkSingularOutPopupClose(true))
                                        }}
                                    >
                                        İşten Çıkış işlemi
                                    </Button>
                                    <p className='mb-0'>Sgk işten çıkış bildirim işlemi</p>
                                </CardBody>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col xl={4} md={6}>
                    <Card>
                        <Row>
                            <Col>
                                <CardBody className='text-sm text-center ps-sm-0'>
                                    <Button
                                        color='primary'
                                        className='text-nowrap mb-1'
                                        onClick={toggleFilePopup}
                                    >
                                        Toplu İşe Giriş İşten Çıkış İşlemi
                                    </Button>
                                    <p className='mb-0'>Excel yükleyerek toplu işe giriş ve çıkış işlemi</p>
                                </CardBody>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row style={{ marginTop: 12 }}>
                <Col xl={6} md={6}>
                    <Card>
                        <Row>
                            <Col>
                                <CardBody className='text-sm text-center ps-sm-0'>
                                    <Button
                                        color='primary'
                                        className='text-nowrap mb-1'
                                        onClick={() => {
                                            dispatch(toggleJobCodePopup(true))
                                        }}
                                    >
                                        Meslek Kodu Güncelle
                                    </Button>
                                    <p className='mb-0'>SGK meslek kodu güncelleme işlemini hızlı ve kolay bir
                                        şekilde gerçekleştirin.</p>
                                </CardBody>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col xl={6} md={6}>
                    <Card>
                        <Row>
                            <Col>
                                <CardBody className='text-sm text-center ps-sm-0'>
                                    <Button
                                        color='primary'
                                        className='text-nowrap mb-1'
                                        onClick={toggleJobCodeFilePopup}
                                    >
                                        Toplu Meslek Kodu Güncelle
                                    </Button>
                                    <p className='mb-0'>SGK toplu meslek kodu güncelleme işlemini hızlı ve kolay bir
                                        şekilde gerçekleştirin.</p>
                                </CardBody>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Card className='mt-5'>
                <div style={{height: 540, width: '100%'}}>
                    <DataGrid
                        rows={storeWorkerList.workerLogs}
                        columns={columns}
                        rowCount={storeWorkerList.workerLogsTotal}
                        pageSize={pageSize}
                        paginationMode="server"
                        sortingMode='server'
                        filterMode='server'
                        // onFilterModelChange={handleFilter}
                        onSortModelChange={handleSortModelChange}
                        onPageChange={(params) => {
                            setPage(params.page)
                        }}
                        onPageSizeChange={(params) => {
                            setPageSize(params.pageSize)
                        }}
                        pageSizeOptions={[5, 10, 25]}
                        slots={{
                            pagination: () => <CustomPagination
                                page={page}
                                pageSize={pageSize}
                                rowCount={storeWorkerList.workerLogsTotal}
                                pageSizeOptions={[5, 10, 25]}
                                onPageChange={(newPage) => {
                                    setPage(newPage)
                                }}
                                onPageSizeChange={(newPageSize) => {
                                    setPageSize(newPageSize)
                                }}
                            />
                        }}
                        localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
                    />
                </div>
            </Card>
            <FilePopup open={storeWorkerList.filePopupOpen} toggleFilePopup={toggleFilePopup}/>
            <JobCodeFilePopup open={storeWorkerList.fileJobCodePopupOpen} toggleJobCodeFilePopup={toggleJobCodeFilePopup}/>
            <SingularSgkOutPopup open={storeWorkerList.singularPopupStatus}/>
            <SidebarSingularSgkEntry open={storeWorkerList.singularEntryPopupStatus}/>
            <JobCodeUpdatePopup open={storeWorkerList.jobCodePopupStatus}/>
        </Fragment>
    )
}

export default RoleCards
