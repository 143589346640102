// ** React Imports
import {Fragment, useEffect, useState} from 'react'

// ** Table Columns
import {allWorkerColumns} from './allWorkerColumns'
import {activeWorkerColumns} from './activeWorkerColumns'

// ** Store & Actions
import {useDispatch, useSelector} from 'react-redux'
import {ToggleHtmlImagePopup, ToggleOutHtmlImagePopup, getDataActiveWorker, getDataAllWorker, exportExcel} from '../store'

// ** Third Party Components
import DataTable from 'react-data-table-component'
import {ChevronDown, Filter, File} from 'react-feather'
import ReactPaginate from 'react-paginate'

import EntyDeclarationHtmlPopup from '../view/EntyDeclarationHtmlPopup'
import OutDeclarationHtmlPopup from '../view/OutDeclarationHtmlPopup'


// ** Reactstrap Imports
import {
    Card,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input,
    Row,
    UncontrolledDropdown,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Button, Label
} from 'reactstrap'

// ** Styles
import '@styles/react/libs/react-select/_react-select.scss'
import '@styles/react/libs/tables/react-dataTable-component.scss'

// ** Table Header
const CustomHeader = ({filter, setFilterHandler, handlePerPage, rowsPerPage, searchTerm, dispatch, activeTab}) => {
    const [showFilter, setShowFilter] = useState(false)
    return (
        <div className='invoice-list-table-header w-100 me-1 ms-50 mt-2 mb-75'>
            <Row>
                <Col xl='6' className='d-flex align-items-center p-0'>
                    <div className='d-flex align-items-center w-100'>
                        <label htmlFor='rows-per-page'>Toplam</label>
                        <Input
                            className='mx-50'
                            type='select'
                            id='rows-per-page'
                            value={rowsPerPage}
                            onChange={handlePerPage}
                            style={{width: '5rem'}}
                        >
                            <option value='10'>10</option>
                            <option value='25'>25</option>
                            <option value='50'>50</option>
                        </Input>
                        <label htmlFor='rows-per-page'>Kayıt Göster</label>
                    </div>
                </Col>
                <Col
                    xl='6'
                    className='d-flex align-items-sm-center justify-content-xl-end justify-content-start flex-xl-nowrap flex-wrap flex-sm-row flex-column pe-xl-1 p-0 mt-xl-0 mt-1'
                >
                    <Button.Ripple onClick={() => setShowFilter(!showFilter)} className='btn-icon me-1 btn-gray'
                                   style={{marginLeft: 10, backgroundColor: 'transparent'}} outline>
                        <Filter className='font-small-4 me-50'/> Filtrele
                    </Button.Ripple>
                    <Button.Ripple onClick={() => {
                        dispatch(exportExcel({filter, activeTab}))
                    }} className='btn-icon me-1 btn-gray' style={{marginLeft: 10, backgroundColor: 'transparent'}}
                                   outline>
                        <File className='font-small-4 me-50'/> Excele Aktar
                    </Button.Ripple>
                    <div className='col-md-3 d-flex align-items-center mb-sm-0 mb-1 me-1'>
                        <Input
                            id='search-invoice'
                            className='ms-50 w-150'
                            type='text'
                            value={searchTerm}
                            placeholder={"Ara"}
                            onChange={e => setFilterHandler('q', e.target.value)}
                        />
                    </div>
                </Col>
            </Row>
            <Row style={{marginTop: 20, display: showFilter ? "" : "none"}}>
                <Col md='3'>
                    <Label for='role-select'>Kimlik</Label>
                    <Input name='text' rows='3' placeholder=''
                           className='react-select'
                           value={filter.IdentificationNumber}
                           onChange={data => {
                               setFilterHandler('IdentificationNumber', data.target.value)
                           }}/>
                </Col>
                <Col md='3'>
                    <Label for='role-select'>İsim</Label>
                    <Input name='text' rows='3' placeholder=''
                           className='react-select'
                           value={filter.Name}
                           onChange={data => {
                               setFilterHandler('Name', data.target.value)
                           }}/>
                </Col>
                <Col md='3'>
                    <Label for='status-select'>Soyisim</Label>
                    <Input name='text' rows='3' placeholder=''
                           className='react-select'
                           value={filter.Surname}
                           onChange={data => {
                               setFilterHandler('Surname', data.target.value)
                           }}/>
                </Col>
                <Col md='3'>
                    <Label for='status-select'>Meslek Adı</Label>
                    <Input name='text' rows='3' placeholder=''
                           className='react-select'
                           value={filter.JobName}
                           onChange={data => {
                               setFilterHandler('JobName', data.target.value)
                           }}/>
                </Col>

            </Row>
            <Row style={{marginTop: 20, display: showFilter ? "" : "none"}}>

                <Col className='my-md-0 my-1' md='4'>
                    <Label for='plan-select'>Firma Adı</Label>
                    <Input name='text' rows='3' placeholder=''
                           className='react-select'
                           value={filter.CompanyName}
                           onChange={data => {
                               setFilterHandler('CompanyName', data.target.value)
                           }}/>
                </Col>
                <Col className='my-md-0 my-1' md='4'>
                    <Label for='plan-select'>Firma Sicil</Label>
                    <Input name='text' rows='3' placeholder=''
                           className='react-select'
                           value={filter.CompanyRegistrationNumber}
                           onChange={data => {
                               setFilterHandler('CompanyRegistrationNumber', data.target.value)
                           }}/>
                </Col>
                <Col md='4'>
                    <Label for='status-select'>Meslek Kodu</Label>
                    <Input name='text' rows='3' placeholder=''
                           className='react-select'
                           value={filter.JobCode}
                           onChange={data => {
                               setFilterHandler('JobCode', data.target.value)
                           }}/>
                </Col>
            </Row>
        </div>
    )
}

const UsersList = () => {
    const dispatch = useDispatch()
    const authStore = useSelector(state => state.auth)
    const storeWorkerList = useSelector(state => state.workerlist)
    const [rowsPerPage, setRowsPerPage] = useState(10)  

    const [activeTable, setActiveTable] = useState({
        sort: '',
        sortColumn: '',
        q: '',
        page: 1,
        pageSize: 10,
        active: 1,
        companyGroupId: authStore.selectedCompanyGroup,
        IdentificationNumber: '',
        Name: '',
        Surname: '',
        CompanyName: '',
        CompanyRegistrationNumber: '',
        JobName: '',
        JobCode: ''
    })
    const [allTable, setAllTable] = useState({
        sort: '',
        sortColumn: '',
        q: '',
        page: 1,
        pageSize: 10,
        active: 2,
        companyGroupId: authStore.selectedCompanyGroup,
        IdentificationNumber: '',
        Name: '',
        Surname: '',
        CompanyName: '',
        CompanyRegistrationNumber: '',
        JobName: '',
        JobCode: ''
    })

    const toggleHtmlImagePopup = () => dispatch(ToggleHtmlImagePopup({show: false, html: null}))
    const toggleOutHtmlImagePopup = () => dispatch(ToggleOutHtmlImagePopup({show: false, html: null}))
    const toggleSidebar = () => setSidebarOpen(!sidebarOpen)
    const [active, setActive] = useState(0)
    
    const setFilterHandler = (parameter, value) => {
        setActiveTable({...activeTable, [parameter]: value, ["page"]: 1})
        setAllTable({...allTable, [parameter]: value, ["page"]: 1})
    }
    const setTableParameter = (parameter, value) => {
        if (active === "1") { //Aktif Çalışan
            setActiveTable({...activeTable, [parameter]: value})
        } else if (active === "2") { // Tüm Çalışan
            setAllTable({...allTable, [parameter]: value})
        }
    }
    const getDataParameter = () => {
        if (active === "1") { //Aktif Çalışan
            return activeTable
        } else if (active === "2") { // Tüm Çalışan
            return allTable
        }
    }

    const toggleTab = tab => {
        setRowsPerPage(10)
        if (active !== tab) {
            setActive(tab)
            setActiveTable({...activeTable, ["IsActive"]: tab === 1, pageSize:10})
            setAllTable({...allTable, ["IsActive"]: tab === 2, pageSize:10})
        }
    }

    const handlePagination = page => {
        setTableParameter("page", page.selected + 1)
    }
    // ** Get data on mount
    useEffect(() => {
        if (active === '2') {
            dispatch(getDataAllWorker(getDataParameter()))
        }
    }, [storeWorkerList.data.length, allTable.sort, allTable.IdentificationNumber, allTable.Name, allTable.q, allTable.Surname, allTable.CompanyName, allTable.CompanyRegistrationNumber, allTable.JobName, allTable.JobCode, allTable.page, authStore.selectedCompanyGroup, active])
    
    const handleFilterAll = val => {
        setActiveTable({...activeTable, ["q"]: val})
        setAllTable({...allTable, ["q"]: val})
        dispatch(
            getDataAllWorker(getDataParameter())
        )
    }

    useEffect(() => {
        if (active === '1') {
            dispatch(getDataActiveWorker(getDataParameter()))
        }
    }, [storeWorkerList.data.length, activeTable.sort, activeTable.sort, activeTable.IdentificationNumber, activeTable.Name, activeTable.q, activeTable.Surname, activeTable.CompanyName, activeTable.CompanyRegistrationNumber, activeTable.JobName, activeTable.JobCode, activeTable.page, authStore.selectedCompanyGroup, active])
    
     const handlePerPageAll = e => {
        const value = parseInt(e.currentTarget.value)
        setRowsPerPage(value)  
        setAllTable(prevState => ({
            ...prevState,
            pageSize: value,
            page: 1  // Sayfa başına satır sayısını değiştirirken sayfayı 1 olarak ayarlar
        }))
        dispatch(getDataAllWorker({
            ...allTable,
            pageSize: value,
            page: 1  // Sayfa başına satır sayısını değiştirirken sayfayı 1 olarak ayarlar
        }))
    }

    const handlePerPageActive = e => {
        const value = parseInt(e.currentTarget.value)
        setRowsPerPage(value) 
        setActiveTable(prevState => ({
            ...prevState,
            pageSize: value,
            page: 1  // Sayfa başına satır sayısını değiştirirken sayfayı 1 olarak ayarlar
        }))
        dispatch(getDataActiveWorker({
            ...activeTable,
            pageSize: value,
            page: 1  // Sayfa başına satır sayısını değiştirirken sayfayı 1 olarak ayarlar
        }))
    }

    // ** Function in get data on search query change
    const handleFilterActive = val => {
        setActiveTable({...activeTable, ["q"]: val, ["page"]: 1})
        setAllTable({...allTable, ["q"]: val, ["page"]: 1})
        dispatch(
            getDataActiveWorker(getDataParameter())
        )
    }
    useEffect(() => {
        toggleTab('1')
    }, [])
    const CustomPaginationActive = () => {
        const total = storeWorkerList.totalActive || 1
        const currentPage = activeTable.page
        return (
            <ReactPaginate
                previousLabel={''}
                nextLabel={''}
                pageCount={total || 1}
                activeClassName='active'
                forcePage={currentPage !== 0 ? currentPage - 1 : 0}
                onPageChange={page => handlePagination(page)}
                pageClassName={'page-item'}
                nextLinkClassName={'page-link'}
                nextClassName={'page-item next'}
                previousClassName={'page-item prev'}
                previousLinkClassName={'page-link'}
                pageLinkClassName={'page-link'}
                containerClassName={'pagination react-paginate justify-content-end my-2 pe-1'}
            />
        )
    }
    const CustomPaginationAll = () => {
        const total = storeWorkerList.totalAll || 1
        const currentPage = allTable.page
        return (
            <ReactPaginate
                previousLabel={''}
                nextLabel={''}
                pageCount={total || 1}
                activeClassName='active'
                forcePage={currentPage !== 0 ? currentPage - 1 : 0}
                onPageChange={page => handlePagination(page)}
                pageClassName={'page-item'}
                nextLinkClassName={'page-link'}
                nextClassName={'page-item next'}
                previousClassName={'page-item prev'}
                previousLinkClassName={'page-link'}
                pageLinkClassName={'page-link'}
                containerClassName={'pagination react-paginate justify-content-end my-2 pe-1'}
            />
        )
    }
    const allDataToRender = () => {
        const filters = {
            q: allTable.q
        }
        const isFiltered = Object.keys(filters).some(function (k) {

            return filters[k].length > 0
        })

        if (storeWorkerList.dataAllWorker.length > 0) {
            return storeWorkerList.dataAllWorker
        } else if (storeWorkerList.dataAllWorker.length === 0 && isFiltered) {
            return []
        } else {
            return storeWorkerList.dataAllWorker.slice(0, allTable.pageSize)
        }
    }
    const activeDataToRender = () => {
        const filters = {
            q: activeTable.q

        }
        const isFiltered = Object.keys(filters).some(function (k) {
            return filters[k].length > 0
        })

        if (storeWorkerList.dataActiveWorker.length > 0) {
            return storeWorkerList.dataActiveWorker
        } else if (storeWorkerList.dataActiveWorker.length === 0 && isFiltered) {
            return []
        } else {
            return storeWorkerList.dataActiveWorker.slice(0, activeTable.pageSize)
        }
    }
    const handleSort = (column, sortDirection) => {
        if (active === "1") { //Aktif Çalışan
            setActiveTable({
                ...activeTable,
                sortColumn: column.sortField,
                sort: sortDirection
            })
        } else if (active === "2") { // Tüm Çalışan
            setAllTable({
                ...allTable,
                sortColumn: column.sortField,
                sort: sortDirection
            })
        }
    }

    return (
        <Fragment>
            <Nav pills>
                <NavItem>
                    <NavLink active={active === '1'} onClick={() => toggleTab('1')}>
                        <span className='fw-bold'>Aktif Personel Listesi</span>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink active={active === '2'} onClick={() => toggleTab('2')}>
                        <span className='fw-bold'>Tüm Personel Listesi</span>
                    </NavLink>
                </NavItem>
            </Nav>
            <Card className="text-center" style={{ color: 'red' }}>
                07.06.2014 Tarihi Öncesinde İşten Ayrılan Personel Bilgileri Bu Listede Yer Almamaktadır!
            </Card>
            <TabContent activeTab={active}>
                <TabPane tabId='1'>
                    <Card className='overflow-hidden'>
                        <div className='react-dataTable'>
                            <DataTable
                                noHeader
                                subHeader
                                sortServer
                                pagination
                                responsive
                                paginationServer
                                columns={activeWorkerColumns}
                                onSort={handleSort}
                                sortIcon={<ChevronDown/>}
                                className='react-dataTable'
                                paginationComponent={CustomPaginationActive}
                                data={activeDataToRender()}
                                subHeaderComponent={
                                    <CustomHeader
                                        store={storeWorkerList}
                                        searchTerm={activeTable.q}
                                        rowsPerPage={rowsPerPage}
                                        handleFilter={handleFilterActive}
                                        handlePerPage={handlePerPageActive}
                                        toggleSidebar={toggleSidebar}
                                        filter={activeTable}
                                        setFilterHandler={setFilterHandler}
                                        dispatch={dispatch}
                                        activeTab={active}
                                    />
                                }
                            />
                        </div>
                    </Card>
                </TabPane>
            </TabContent>
            <TabContent activeTab={active}>
                <TabPane tabId='2'>
                    <Card className='overflow-hidden'>
                        <div className='react-dataTable'>
                            <DataTable
                                noHeader
                                subHeader
                                sortServer
                                pagination
                                responsive
                                paginationServer
                                columns={allWorkerColumns}
                                onSort={handleSort}
                                sortIcon={<ChevronDown/>}
                                className='react-dataTable'
                                paginationComponent={CustomPaginationAll}
                                data={allDataToRender()}
                                subHeaderComponent={
                                    <CustomHeader
                                        store={storeWorkerList}
                                        searchTerm={activeTable.q}
                                        rowsPerPage={rowsPerPage}
                                        handleFilter={handleFilterAll}
                                        handlePerPage={handlePerPageAll}
                                        toggleSidebar={toggleSidebar}
                                        filter={activeTable}
                                        setFilterHandler={setFilterHandler}
                                        dispatch={dispatch}
                                        activeTab={active}
                                    />
                                }
                            />
                        </div>
                    </Card>
                </TabPane>
            </TabContent>
            <EntyDeclarationHtmlPopup open={storeWorkerList.toggleHtmlImagePopup?.show}
                                      toggleSidebar={toggleHtmlImagePopup}/>
            <OutDeclarationHtmlPopup open={storeWorkerList.toggleOutHtmlImagePopup?.show}
                                      toggleSidebar={toggleOutHtmlImagePopup}/>
        </Fragment>
    )
}

export default UsersList
